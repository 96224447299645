import { Link } from 'react-router-dom';
import logo from '../assets/images/logo.png';
import RegisterButton from './RegisterButton';
import LoginButton from './LoginButton';

export default function Navbar() {

    const isLoggedIn = () => {
        return localStorage.getItem('access_token') !== null;
    }

    const logout = () => {
        localStorage.removeItem('access_token');
        window.location.href = '/';
    }

    return (
        <nav className="navbar navbar-expand-lg fixed-top navbar-bottom">
            <div className="container-fluid">
                <Link className="navbar-brand" to="/">
                    <img src={logo} alt="logo" className="d-inline-block align-text-top me-4" width="80" height="55" />
                </Link>
                <div className="d-flex flex-row align-items-center">
                    <Link className='navbar-register me-3 text-decoration-none d-lg-none' to="/register">REGISTER</Link>
                    <Link className='navbar-login me-3 text-decoration-none d-lg-none' to="/login">LOGIN</Link>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <i className="fa-solid fa-bars p-yellow mt-1 mb-1"></i>
                    </button>
                </div>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                        <li className="nav-item">
                            <Link to="/home" className={`nav-link me-4 ${window.location.pathname === '/' ? 'active' : ''}`} aria-current="page">Home</Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/academic" className={`nav-link me-4 ${window.location.pathname === '/academic' ? 'active' : ''}`}>Academic</Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/non-academic" className={`nav-link me-4 ${window.location.pathname === '/non-academic' ? 'active' : ''}`}>Non-Academic</Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/guidebook" className={`nav-link me-4 ${window.location.pathname === '/guidebook' ? 'active' : ''}`}>Guidebook</Link>
                        </li>
                        {/* <li className="nav-item">
                            <Link to="/contact-us" className={`nav-link me-4 ${window.location.pathname === '/contact-us' ? 'active' : ''}`}>Contact Us</Link>
                        </li> */}
                    </ul>
                </div>
            </div>
            <div className="d-none d-lg-inline-flex">
                {isLoggedIn() ?
                    <>
                    <div className='me-2 ms-2'>
                        <Link to={`/lobby`} className='text-decoration-none'>
                            <i className="fa-regular fa-user fa-1x me-4 logged-icon"></i>
                        </Link>
                    </div>
                    <div className='me-2 ms-2'>
                        <div className='text-decoration-none' onClick={() => logout()}>
                            <i className="fa-solid fa-power-off fa-1x me-4 logged-icon"></i>   
                        </div>
                    </div>
                    </>
                    :
                    <>
                        <div className='me-2 ms-2'>
                            <LoginButton />
                        </div>
                        <div className='me-2 ms-2'>
                            <RegisterButton />
                        </div>
                    </>
                }
            </div>
        </nav>
    )
} 