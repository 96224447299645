import React from 'react';
import ReactDOM from 'react-dom';
import {
  RouterProvider
} from "react-router-dom";
import reportWebVitals from './reportWebVitals';
import './assets/css/main.css';
import router from './routes';
import axios from 'axios';

import "toastify-js/src/toastify.css"
import Toastify from 'toastify-js'

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.headers.common['Accept'] = 'application/json';
axios.defaults.headers.common['Content-Type'] = 'application/json';
axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('access_token');

// axios interceptor response if 401  
axios.interceptors.response.use(
  response => response,
  error => {
    if (error.response.status === 401) {
      localStorage.removeItem('access_token');
      Toastify({
        text: "Your session has expired. Please login.",
        duration: 3000,
        gravity: "top",
        position: "right",
        backgroundColor: "#f44336",
        stopOnFocus: true,
        onClick: function () { 
          window.location.href = '/login';
        },
        callback: function () {
          window.location.href = '/login';
        },
      }).showToast();
    }
    return Promise.reject(error);
  }
);

ReactDOM.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();
