import Joi from "joi";

export const resetPasswordSchema = Joi.object({
    token: Joi.string().required().messages({
        "string.empty": "Token cannot be empty.",
        "any.required": "Token is required."
    }),
    password: Joi.string().required().messages({
        "string.empty": "Password cannot be empty.",
        "any.required": "Password is required."
    }),
    confirm_password: Joi.string().valid(Joi.ref('password')).required().messages({
        'string.empty': `Confirm password cannot be empty.`,
        'any.only': `Confirm password must match password.`,
        'any.required': `Confirm password is required.`
    })
});