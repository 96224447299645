import { Link } from "react-router-dom";

export default function LobbyButton() {
    return (
        <Link to="/lobby" className="text-decoration-none">
            <div className="custom-button btn-custom-login">
                <i className="fa-regular fa-user fa-1x"></i> Lobby
            </div>
        </Link>
    )
}