import { useEffect, useState } from "react";
import BackgroundV2 from "../../components/BackgroundV2";
import NavbarLogo from "../../components/NavbarLogo";
import { Helmet } from "react-helmet";
import { profileSchema } from "../../schema/profile.schema";
import axios from "axios";
import Toastify from 'toastify-js'
import Preload from "../../components/Preload";
import { Link } from "react-router-dom";

export default function Profile() {

    const [userInfo, setUserInfo] = useState(null);
    const [loading, setLoading] = useState(true);

    const [previewImage, setPreviewImage] = useState(null); // [previewImage, setPreviewImage
    const [image, setImage] = useState(null);
    const [firstName, setFirstName] = useState('');
    const [middleName, setMiddleName] = useState('');
    const [lastName, setLastName] = useState('');
    const [nickname, setNickname] = useState('');
    const [age, setAge] = useState(-1);
    const [undergraduate, setUndergraduate] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [food, setFood] = useState('');
    const [allergies, setAllergies] = useState('');
    const [disease, setDisease] = useState('');
    const [others, setOthers] = useState('');
    const [foodDetail, setFoodDetail] = useState('');

    useEffect(() => {
        // Wait for 2 get request to complete then
        Promise.all([getUserInfo(), getProfile()]);
    }, [])

    const handleImageChange = (e) => {
        setImage(e.target.files[0]);

        const reader = new FileReader();
        reader.onload = function (e) {
            document.getElementById('avatar').src = e.target.result;
        }
        reader.readAsDataURL(e.target.files[0]);
    }

    const save = () => {
        const payload = {
            image: image,
            first_name: firstName,
            middle_name: middleName,
            last_name: lastName,
            nickname: nickname,
            age: age,
            undergraduate: undergraduate.toString(),
            email: email,
            phone: phone,
            food: food,
            allergies: allergies,
            disease: disease,
            others: others,
            food_detail: foodDetail
        }

        const validate = profileSchema.validate(payload, { abortEarly: false });
        if (validate?.error) {
            validate.error.details.forEach((err) => {
                document.getElementById(err.context.key).classList.add('is-invalid');
                document.getElementById(err.context.key + 'Feedback').innerHTML = err.message;
                console.log(err)
            })
            return;
        }

        const originalText = document.getElementById('saveProfile').innerHTML;
        document.getElementById('saveProfile').innerHTML = '<i class="fa-solid fa-circle-notch fa-spin text-white"></i>';
        document.getElementById('saveProfile').disabled = true;

        // multipart/form-data
        const formData = new FormData();
        formData.append('image', image);
        formData.append('first_name', firstName);
        formData.append('middle_name', middleName);
        formData.append('last_name', lastName);
        formData.append('nickname', nickname);
        formData.append('age', age);
        formData.append('undergraduate', undergraduate ?? '');
        formData.append('email', email ?? '');
        formData.append('phone', phone ?? '');
        formData.append('food', food ?? '');
        formData.append('allergies', allergies ?? '');
        formData.append('disease', disease ?? '');
        formData.append('others', others ?? '');
        formData.append('food_detail', foodDetail ?? '');

        axios.post('/api/profile', formData, {
            headers: {
                common: {
                    'Content-Type': 'multipart/form-data',
                }
            }
        }).then((res) => {
            if (res.status === 200) {
                return Toastify({
                    text: 'Your profile has been saved.',
                    duration: 3000,
                    gravity: 'bottom',
                    position: 'center',
                    backgroundColor: 'linear-gradient(to right, #00b09b, #96c93d)',
                    className: 'info',
                }).showToast();
            } else {
                return Toastify({
                    text: 'An error has occurred. Please try again later.',
                    duration: 3000,
                    gravity: 'bottom',
                    position: 'center',
                    backgroundColor: 'linear-gradient(to right, #ff5f6d, #ffc371)',
                    className: 'error',
                }).showToast();
            }
        }).catch((err) => {
            if (err.response) {
                const errors = err.response.data?.errors;
                if (errors) {
                    Object.keys(errors).forEach((key) => {
                        document.getElementById(key).classList.add('is-invalid');
                        document.getElementById(key + 'Feedback').innerHTML = errors[key][0];
                    })
                } else {
                    return Toastify({
                        text: 'An error has occurred. Please try again later.',
                        duration: 3000,
                        gravity: 'bottom',
                        position: 'center',
                        backgroundColor: 'linear-gradient(to right, #ff5f6d, #ffc371)',
                        className: 'error',
                    }).showToast();
                }
            }
        }).finally(() => {
            console.log('Request completed.');
            document.getElementById('saveProfile').innerHTML = originalText;
            document.getElementById('saveProfile').disabled = false;
        })
    }

    const getProfile = () => {
        const token = localStorage.getItem('access_token');
        axios.get('/api/profile', { token }).then((res) => {
            setAge(res.data.data.age);
            setUndergraduate(res.data.data.undergraduate ?? '');
            setFood(res.data.data.food ?? '');
            setPhone(res.data.data.phone ?? '');
            setAllergies(res.data.data.allergies ?? '');
            setDisease(res.data.data.disease ?? '');
            setOthers(res.data.data.others ?? '');
            setFoodDetail(res.data.data.food_detail ?? '');
        }).catch((err) => {
            console.log(err);
        }).finally(() => {
            setLoading(false);
            console.log('Request completed.');
        })
    }

    const getUserInfo = () => {
        const token = localStorage.getItem('access_token');
        axios.get('/api/auth/me', { token }).then((res) => {
            setUserInfo(res.data);
            setFirstName(res.data.first_name);
            setMiddleName(res.data?.middle_name ?? '');
            setLastName(res.data.last_name);
            setEmail(res.data.email);
            if (res.data?.image_url) {
                setPreviewImage(res.data.image_url);
            }
            setNickname(res.data.nickname ?? '');
        }).catch((err) => {
            console.log(err);
        }).finally(() => {
            console.log('Request completed.');
        })
    }

    const onInputChange = (e) => {
        const { id, value } = e.target;
        if (id === 'first_name') {
            setFirstName(value);
        } else if (id === 'middle_name') {
            setMiddleName(value);
        } else if (id === 'last_name') {
            setLastName(value);
        } else if (id === 'nickname') {
            setNickname(value);
        } else if (id === 'age') {
            setAge(value);
        } else if (id === 'undergraduate') {
            setUndergraduate(value);
        } else if (id === 'email') {
            setEmail(value);
        } else if (id === 'phone') {
            setPhone(value);
        } else if (id === 'food') {
            setFood(value);
        } else if (id === 'allergies') {
            setAllergies(value);
        } else if (id === 'disease') {
            setDisease(value);
        } else if (id === 'others') {
            setOthers(value);
        } else if (id === 'food_detail') {
            setFoodDetail(value);
        }
        document.getElementById(id).classList.remove('is-invalid');
        document.getElementById(id + 'Feedback').innerHTML = '';
    }

    const getDefaultRole = () => {
        if (userInfo?.role === 'advisor' || userInfo?.role === 'observer') {
            return userInfo?.role; 
        } else {
            return undergraduate
        }
     }

    return (
        <>
            <BackgroundV2 props={{ height: '75vh' }} />
            <Helmet>
                <style>{`
                    html, body {
                        padding-top: 75px;
                    }
                `}</style>
            </Helmet>
            <NavbarLogo />

            {!loading ?
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="card transparent-bg border-0">
                                <div className="card-body">
                                    <h3 className="mb-3 text-center"><span className="label-blue">Personal</span> <span className="label-red">Information</span></h3>

                                    <div className="row justify-content-center">
                                        <div className="col-12 col-md-6">
                                            <div className="card border-white transparent-bg text-white">
                                                <div className="card-body">
                                                    <div className="row justify-content-center">
                                                        <div className="col-4 col-md-4">
                                                            <div className="position-relative">
                                                                <img src={previewImage ?? "https://pub-3d6ffa377ce448019676a38678d8594b.r2.dev/user.png"} alt="team" className="rounded-circle img-fluid w-100 h-100" id="avatar" style={{ border: '5px solid #fff' }} />
                                                                <input type="file" className="d-none" id="image" hidden onChange={handleImageChange} accept="image/*" />
                                                                <div className="position-absolute bottom-0 end-0">
                                                                    <label htmlFor="image">
                                                                        <i className="fas fa-plus-circle fa-2x text-danger"></i>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row mt-3 text-white">
                                        <div className="col-6 col-lg-3">
                                            <div className="form-group mb-3">
                                                <label htmlFor="first_name" className="form-label">First name <span className="text-danger">*</span></label>
                                                <input type="text" className="form-control" id="first_name" onChange={onInputChange} value={firstName} />
                                                <div className="invalid-feedback" id="first_nameFeedback"></div>
                                            </div>
                                        </div>

                                        <div className="col-6 col-lg-3">
                                            {/* middle name */}
                                            <div className="form-group mb-3">
                                                <label htmlFor="middle_name" className="form-label">Middle name</label>
                                                <input type="text" className="form-control" id="middle_name" onChange={onInputChange} value={middleName} />
                                                <div className="invalid-feedback" id="middle_nameFeedback"></div>
                                            </div>
                                        </div>

                                        <div className="col-6 col-lg-3">
                                            <div className="form-group mb-3">
                                                <label htmlFor="last_name" className="form-label">Last name <span className="text-danger">*</span></label>
                                                <input type="text" className="form-control" id="last_name" onChange={onInputChange} value={lastName} />
                                                <div className="invalid-feedback" id="last_nameFeedback"></div>
                                            </div>
                                        </div>

                                        <div className="col-6 col-lg-3">
                                            <div className="form-group mb-3">
                                                <label htmlFor="nickname" className="form-label">Nickname <span className="text-danger">*</span></label>
                                                <input type="text" className="form-control" id="nickname" onChange={onInputChange} value={nickname} />
                                                <div className="invalid-feedback" id="nicknameFeedback"></div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row mt-1 text-white">
                                        <div className="col-12 col-lg-6">
                                            <div className="form-group mb-3">
                                                <label htmlFor="age" className="form-label">
                                                <div className="d-none d-lg-block"><div style={{ fontSize: 10 }}>ㅤ</div></div>
                                                    <div>Age <span className="text-danger">*</span></div>
                                                </label>
                                                <input type="number" className="form-control" id="age" placeholder="Please fill out your information" onChange={onInputChange} value={age === -1 ? '' : age} />
                                                <div className="invalid-feedback" id="ageFeedback"></div>
                                            </div>
                                        </div>

                                        <div className="col-12 col-lg-6">
                                            <div className="form-group mb-3">
                                                <label htmlFor="undergraduate" className="form-label">
                                                    <div>Undergraduate education <span className="text-danger">*</span> </div>
                                                    <div style={{ fontSize: 10 }}>(If you are an advisor or observer, please select advisor or observer)</div>
                                                </label>
                                                <select className="form-select" id="undergraduate" onChange={onInputChange} defaultValue={getDefaultRole()}>
                                                    <option value="0" disabled>Please select your undergraduate education</option>
                                                    <option value="1" selected={undergraduate === 1}>1</option>
                                                    <option value="2" selected={undergraduate === 2}>2</option>
                                                    <option value="3" selected={undergraduate === 3}>3</option>
                                                    <option value="4" selected={undergraduate === 4}>4</option>
                                                    <option value="5" selected={undergraduate === 5}>5</option>
                                                    <option value="6" selected={undergraduate === 6}>6</option>
                                                    <option value="7" selected={undergraduate === 7}>7</option>
                                                    <option value="8" selected={undergraduate === 8}>8</option>
                                                    <option value="advisor" selected={undergraduate === 'advisor'}>Advisor</option>
                                                    <option value="observer" selected={undergraduate === 'observer'}>Observer</option>
                                                </select>
                                                <div className="invalid-feedback" id="undergraduateFeedback"></div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* email and phone number */}
                                    <div className="row mt-1 text-white">
                                        <div className="col-12 col-lg-6">
                                            <div className="form-group mb-3">
                                                <label htmlFor="email" className="form-label">Email <span className="text-danger">*</span></label>
                                                <input type="email" className="form-control" id="email" placeholder="Please fill out your information" onChange={onInputChange} value={email} />
                                                <div className="invalid-feedback" id="emailFeedback"></div>
                                            </div>
                                        </div>

                                        <div className="col-12 col-lg-6">
                                            <div className="form-group mb-3">
                                                <label htmlFor="phone" className="form-label">Phone number </label>
                                                <input type="tel" className="form-control" id="phone" placeholder="Please fill out your information" onChange={onInputChange} value={phone} />
                                                <div className="invalid-feedback" id="phoneFeedback"></div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* Food and Food/Drug allergies */}
                                    <div className="row mt-1 text-white">
                                        <div className="col-12 col-lg-6">
                                            <div className="form-group mb-3">
                                                <label htmlFor="food" className="form-label">Food <span className="text-danger">*</span></label>
                                                <select className="form-select" id="food" onChange={onInputChange} defaultValue={food === '' ? '0' : food}>
                                                    <option value="0" disabled>Please select your food</option>
                                                    <option value="Vegetarian" selected={food === 'Vegetarian'}>Vegetarian</option>
                                                    <option value="Halal" selected={food === 'Halal'}>Halal</option>
                                                    <option value="Normal" selected={food === 'Normal'}>Normal</option>
                                                    <option value="Other" selected={food === 'Other'}>Other</option>
                                                </select>
                                                <div className="invalid-feedback" id="foodFeedback"></div>
                                            </div>
                                        </div>

                                        <div className="col-12 col-lg-6">
                                            <div className="form-group mb-3">
                                                <label htmlFor="allergies" className="form-label">Food/Drug allergies</label>
                                                <input type="text" className="form-control" id="allergies" placeholder="Please fill out your information" onChange={onInputChange} value={allergies} />
                                                <div className="invalid-feedback" id="allergiesFeedback"></div>
                                            </div>
                                        </div>

                                        {food === 'Other' &&
                                            <div className="col-12 col-lg-6">
                                                <div className="form-group mb-3">
                                                    <label htmlFor="food_detail" className="form-label">Food detail</label>
                                                    <input type="text" className="form-control" id="food_detail" placeholder="Please specify your food" onChange={onInputChange} value={foodDetail} />
                                                    <div className="invalid-feedback" id="food_detailFeedback"></div>
                                                </div>
                                            </div>
                                        }
                                    </div>

                                    {/* Underlying disease and Others */}
                                    <div className="row mt-1 text-white">
                                        <div className="col-12 col-lg-6">
                                            <div className="form-group mb-3">
                                                <label htmlFor="disease" className="form-label">Underlying disease</label>
                                                <input type="text" className="form-control" id="disease" placeholder="Please fill out your information" onChange={onInputChange} value={disease} />
                                                <div className="invalid-feedback" id="diseaseFeedback"></div>
                                            </div>
                                        </div>

                                        <div className="col-12 col-lg-6">
                                            <div className="form-group mb-3">
                                                <label htmlFor="others" className="form-label">Others</label>
                                                <input type="text" className="form-control" id="others" placeholder="Please fill out your information" onChange={onInputChange} value={others} />
                                                <div className="invalid-feedback" id="othersFeedback"></div>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="row mt-3 mb-4">
                                        <div className="col-12 text-center">
                                            <button className="btn red-neon-button" id="saveProfile" onClick={() => save()}>Save</button>
                                        </div>
                                        <div className="col-12 text-center mt-3">
                                            <Link to="/lobby" className="text-white">Go to lobby</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                :
                <Preload />
            }
        </>
    )
}