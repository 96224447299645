import { useState } from "react";
import Background from "../components/Background";
import NavbarLogo from "../components/NavbarLogo";
import { Link } from "react-router-dom";
import Padding from "../components/Padding";
import { forgotPasswordSchema } from "../schema/forgot-password.schema";
import axios from "axios";

export default function ForgotPassword() {

    const [isSent, setIsSent] = useState(false);
    const [email, setEmail] = useState("");

    const requestForgotPasswordLink = () => {
        const validate = forgotPasswordSchema.validate({ email: email }, { abortEarly: false });
        if (validate?.error) {
            validate.error.details.forEach((err) => {
                document.getElementById(err.context.key).classList.add('is-invalid');
                document.getElementById(err.context.key + 'Feedback').innerHTML = err.message;
            })
            return;
        }

        const originalText = document.getElementById('send').innerHTML;
        document.getElementById('send').innerHTML = '<i class="fa-solid fa-circle-notch fa-spin text-white"></i>';
        
        axios.post('/api/forgot-password', { email: email }).then((res) => {
            if (res.data?.status === 'success') {
                setIsSent(true);
            } else {
                document.getElementById('send').innerHTML = originalText;
                document.getElementById('email').classList.add('is-invalid');
                document.getElementById('emailFeedback').innerHTML = 'Something went wrong. Please try again.';
            }
        }).catch((err) => {
            if (err.response) {
                const errors = err.response.data.errors;
                if (errors) {
                    Object.keys(errors).forEach((key) => {
                        document.getElementById(key).classList.add('is-invalid');
                        document.getElementById(key + 'Feedback').innerHTML = errors[key][0];
                    })
                }
            }
            document.getElementById('send').innerHTML = originalText;
        }).finally(() => {
            console.log('Request completed.');
        })
    }

    return (
        <>
            <Padding />
            <Background />
            <NavbarLogo />
            {!isSent ?
            <div className="d-flex justify-content-center h-100 align-items-center flex-column">
                <div className="form-group mb-4">
                    <i className="fa-solid fa-key text-white fa-3x"></i>
                </div>
                <h1 className="mb-5 text-center"><span className="label-blue">Forgot</span> <span className="label-red">password?</span></h1>
                <div className="login-label mb-3 pe-3 ps-3 text-center">Enter your email address to reset your password.</div>

                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-6 col-lg-4">
                            <div className="form-group mb-3">
                                <input type="email" className="form-control" placeholder="Email address" id="email" onChange={(e) => setEmail(e.target.value)} />
                                <div className="invalid-feedback" id="emailFeedback"></div>
                            </div>

                            <div className="d-flex justify-content-center mb-4">
                                <div className="custom-button btn-custom-register" id="send" onClick={() => requestForgotPasswordLink()}>Send</div>
                            </div>

                            <div className="text-white mb-4 text-center">Back to <Link to="/login" className="text-decoration text-white">Login</Link> ?</div>
                        </div>
                    </div>
                </div>
            </div>
            : 
            <div className="d-flex justify-content-center h-100 align-items-center flex-column">
                <div className="form-group mb-4">
                    <i className="fa-solid fa-envelope fa-3x text-white"></i>
                </div>
                <h1 className="mb-5 text-center"><span className="label-blue">CHECK</span> <span className="label-red">YOUR email</span></h1>
                <div className="login-label mb-3 pe-3 ps-3 text-center">We have sent you an email with a link to reset your password.</div>

                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-6 col-lg-4">
                            {/* <div className="d-flex justify-content-center mb-4">
                                <div className="custom-button btn-custom-register" onClick={() => requestForgotPasswordLink()}>Resend email</div>
                            </div> */}

                            <div className="text-white mb-4 text-center">Back to <Link to="/login" className="text-decoration text-white">Login</Link> ?</div>
                        </div>
                    </div>
                </div>
            </div>
            }
        </>
    )
}