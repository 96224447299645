import { Helmet } from "react-helmet";
import BackgroundV2 from "../components/BackgroundV2";
import Navbar from "../components/Navbar";
import LeftComponetText from "../components/LeftComponetText";

import section1 from '../assets/images/home/section1.png';
import section2 from '../assets/images/home/section2.png';

import RegisterButton from "../components/RegisterButton";
import RightComponetText from "../components/RightComponetText";
import Timeline from "../components/Timeline";
import Footer from "../components/Footer";
import Agenda from "../components/Agenda";
import Gallery from "../components/Gallery";

export default function Home() {

    const renderContentSection1 = () => {
      return (
        <div className="ps-lg-5 d-flex flex-column justify-content-center h-100">
          <h1 className="text-center text-lg-start mb-3"><span className="label-blue">What is</span><span className="label-red"> KKU - IC:EM ?</span></h1>
          <div className="text-center text-lg-start description">KKU ICEM (n.)</div>
          <div className="text-center text-lg-start description mb-3">Khon Kaen University International Challenge of Emergency Medicine and related basic sciences </div>
          <div className="text-center text-lg-start description mb-4">KKU ICEM is an emergency medicine competition where medical students from all around the world compete to become “The best of emergency medicine”.</div>
          <div className="w-100 d-flex justify-content-center justify-content-lg-start">
            <RegisterButton />
          </div>
        </div>
      )
    }

    const renderImageSection1 = () => {
      return (
        <div className="text-center text-lg-end">
          <img src={section1} alt="section1" className="img-fluid" />
        </div>
      )
    }

    const renderContentSection2 = () => {
      return (
        <div className="pe-lg-5 d-flex flex-column justify-content-center h-100">
          <h1 className="mb-3 text-center text-lg-start"><span className="label-blue">What’s happening</span><span className="label-red"> this year ?</span></h1>
          <div className="description text-center text-lg-start">As long as being an exciting academic competition, ICEM provides an unforgettable experience through beautiful cultures from many countries.</div>
        </div>
      )
    }

    const renderImageSection2 = () => {
      return (
        <div className="text-center text-lg-start">
          <img src={section2} alt="section1" className="img-fluid" />
        </div>
      )
    }

    return (
        <>
            <BackgroundV2 />
            <Navbar />
            <Helmet>
                <style>{`
                    html, body {
                        padding-top: 60px;
                    }
                `}</style>
            </Helmet>
            <div className="container-fluid">
              <LeftComponetText props={{content: renderContentSection1(), image: renderImageSection1()}} />
              <RightComponetText props={{content: renderContentSection2(), image: renderImageSection2()}} />
              <Timeline />
              <Agenda />
              <Gallery />
            </div>
            <Footer />
        </>
    )
}