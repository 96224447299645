import Joi from "joi";

export const profileSchema = Joi.object({
    // image File   
    image: Joi.object().optional().allow(null, '').messages({
        'object.base': `Image must be a file.`,
    }),
    first_name: Joi.string().required().min(1).max(64).messages({
        'string.empty': `First name cannot be empty.`,
        'string.min': `First name must be at least {#limit} characters.`,
        'string.max': `First name cannot be longer than {#limit} characters.`,
        'any.required': `First name is required.`
    }),
    middle_name: Joi.string().optional().allow(null, '').max(64).messages({
        'string.max': `Middle name cannot be longer than {#limit} characters.`,
    }),
    last_name: Joi.string().required().min(1).max(64).messages({
        'string.empty': `Last name cannot be empty.`,
        'string.min': `Last name must be at least {#limit} characters.`,
        'string.max': `Last name cannot be longer than {#limit} characters.`,
        'any.required': `Last name is required.`
    }),
    nickname: Joi.string().required().min(1).max(64).messages({
        'string.empty': `Nickname cannot be empty.`,
        'string.min': `Nickname must be at least {#limit} characters.`,
        'string.max': `Nickname cannot be longer than {#limit} characters.`,
        'any.required': `Nickname is required.`
    }),
    age: Joi.number().required().min(1).max(100).messages({
        'number.empty': `Age cannot be empty.`,
        'number.min': `Age must be at least {#limit} years old.`,
        'number.max': `Age cannot be older than {#limit} years old.`,
        'any.required': `Age is required.`
    }),
    undergraduate: Joi.string().required().messages({
        'string.empty': `Undergraduate cannot be empty.`,
        'any.required': `Undergraduate is required.`
    }),
    email: Joi.string().required().email({ tlds: { allow: false } }).messages({
        'string.empty': `Email cannot be empty.`,
        'string.email': `Email must be a valid email.`,
        'any.required': `Email is required.`
    }),
    phone: Joi.allow(null, '').optional(),
    food: Joi.string().required().messages({
        'string.empty': `Food allergies cannot be empty.`,
        'any.required': `Food allergies is required.`
    }),
    allergies: Joi.allow(null, '').optional(),
    disease: Joi.allow(null, '').optional(),
    others: Joi.allow(null, '').optional(),
    food_detail: Joi.allow(null, '').optional(),
})