import Joi from "joi";
import { tlds } from '@hapi/tlds';

export const loginSchema = Joi.object({
    email: Joi.string().email({ tlds: { allow: tlds } }).required().messages({
        'string.empty': `Email cannot be empty.`,
        'string.email': `Email must be a valid email.`,
        'any.required': `Email is required.`
    }),
    password: Joi.string().required().messages({
        'string.empty': `Password cannot be empty.`,
        'any.required': `Password is required.`
    })
});
