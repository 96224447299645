export default function LabelState({ props }) {

    const renderIcon = (status) => {
        switch (status) {
            case 'pending':
                return <i className="fa-regular fa-clock me-3"></i>
            case 'processing':
                return <i className="fa-solid fa-gear me-3"></i>
            case 'approved':
                return <i className="fa-solid fa-circle-check me-3"></i>
            case 'rejected':
                return <i className="fa-solid fa-circle-xmark me-3"></i>
            default:
                return <i className="fa-regular fa-clock me-3"></i>

        }
    }

    return (
        <div className={`h-100 status ${props.status} d-flex align-items-center justify-content-center`}>
            {renderIcon(props.status)} {props.text}
        </div>
    )
}