import { Link } from "react-router-dom";
import Logo from "../assets/images/logo.png";
import facebook from '../assets/images/facebook.png';
import email from '../assets/images/mail.svg';
import ig from '../assets/images/ig.png';


export default function Footer() {
    return (
        <div className="container-fluid mb-5 pb-0">
            <div className="row pe-lg-5 ps-lg-5">
                <div className="col-12 text-center">
                    <img className="img-fluid" src={Logo} alt="KKU ICEM 2024" width={150} />
                </div>
            </div>
            <div className="row pe-lg-5 ps-lg-5 justify-content-center align-items-center mt-4 mb-4">
                <div className="col-6 col-lg-2 text-center">
                    <Link to="/home" className={`nav-link text-center ${window.location.pathname === '/' ? 'active' : ''}`} aria-current="page">Home</Link>
                </div>
                <div className="col-6 col-lg-2 text-center">
                    <Link to="/academic" className={`nav-link text-center ${window.location.pathname === '/academic' ? 'active' : ''}`}>Academic</Link>
                </div>
                <div className="col-6 col-lg-2 text-center">
                    <Link to="/non-academic" className={`nav-link text-center ${window.location.pathname === '/non-academic' ? 'active' : ''}`}>Non-Academic</Link>
                </div>
                <div className="col-6 col-lg-2 text-center">
                    <Link to="/guidebook" className={`nav-link text-center ${window.location.pathname === '/guidebook' ? 'active' : ''}`}>Guidebook</Link>
                </div>
            </div>

            <div className="row pe-lg-5 ps-lg-5 justify-content-center align-items-center">
                <div className="col-4 d-flex justify-content-center align-items-center">
                    <Link to="https://www.facebook.com/kkuicem?mibextid=ViGcVu" className="me-3 ms-3"><i className="fa-brands fa-square-facebook fa-2x text-white"></i></Link>
                    <Link to="https://instagram.com/kkuicem2024?igshid=OGQ5ZDc2ODk2ZA==" className="me-3 ms-3"><i className="fa-brands fa-square-instagram fa-2x text-white"></i></Link>
                </div>
            </div>

            <div className="row pe-lg-5 ps-lg-5 justify-content-center align-items-center mb-4 mt-4">
                <div className="col-12">
                    <div className="divider"></div>
                </div>
            </div>

            <div className="row pe-lg-5 ps-lg-5 justify-content-center align-items-center">
                <div className="col-12 col-md-4 mb-4">
                    <a href="https://instagram.com/kkuicem2024?igshid=OGQ5ZDc2ODk2ZA==" className="text-decoration-none">
                        <div className="d-flex justify-content-start align-items-center justify-content-lg-center w-100">
                            <div className="me-0 ms-0 w-25 text-start text-lg-center">
                                <img src={ig} alt="phone" className="img-fluid" />
                            </div>
                            <div className="text-end text-lg-start w-75 me-0 ms-0">
                                <div style={{ color: '#666666', fontSize: 12 }}>Instragram</div>
                                <div style={{ color: '#FFF', fontSize: 16 }}>KKUICEM2024</div>
                            </div>
                        </div>
                    </a>
                </div>
                <div className="col-12 col-md-4 mb-4">
                    <a href="https://www.facebook.com/kkuicem?mibextid=ViGcVu" className="text-decoration-none">
                        <div className="d-flex justify-content-start align-items-center justify-content-lg-center w-100">
                            <div className="me-0 ms-0 w-25 text-start text-lg-center">
                                <img src={facebook} alt="email" className="img-fluid" />
                            </div>
                            <div className="text-end text-lg-start w-75 me-0 ms-0">
                                <div style={{ color: '#666666', fontSize: 12 }}>Facebook</div>
                                <div style={{ color: '#FFF', fontSize: 16 }}>KKU ICEM</div>
                            </div>
                        </div>
                    </a>
                </div>
                <div className="col-12 col-md-4 mb-4">
                    <a href="mailto:kkuicem.official@gmail.com" className="text-decoration-none">
                        <div className="d-flex justify-content-start align-items-center justify-content-lg-center w-100">
                            <div className="me-0 ms-0 w-25 text-start text-lg-center">
                                <img src={email} alt="email" className="img-fluid" />
                            </div>
                            <div className="text-end text-lg-start w-75 me-0 ms-0">
                                <div style={{ color: '#666666', fontSize: 12 }}>Email</div>
                                <div style={{ color: '#FFF', fontSize: 16 }}>kkuicem.official@gmail.com</div>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    )
}