import Background from "../components/Background";
import NavbarLogo from "../components/NavbarLogo";
import { Link } from "react-router-dom";
import Padding from "../components/Padding";
import { useState } from "react";
import { loginSchema } from "../schema/login.shcema";
import axios from "axios";
import Toastify from "toastify-js";
import RegisterTerm from "../components/RegisterTerm";
import { Helmet } from "react-helmet";

export default function Login() {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const login = () => {

        const originalText = document.getElementById('login_button').innerHTML;
        document.getElementById('login_button').innerHTML = '<i class="fa-solid fa-circle-notch fa-spin text-white"></i>';


        const payload = {
            email: email,
            password: password
        }

        const validate = loginSchema.validate(payload, { abortEarly: false });
        if (validate?.error) {
            validate.error.details.forEach((err) => {
                document.getElementById(err.context.key).classList.add('is-invalid');
                document.getElementById(err.context.key + 'Feedback').innerHTML = err.message;
            })
            document.getElementById('login_button').innerHTML = originalText;
            return;
        }

        axios.post('/api/auth/login', payload).then((res) => {
            if (res.data.access_token) {
                localStorage.setItem('access_token', res.data.access_token);
                window.location.href = '/lobby';
            }
        }).catch((err) => {
            if (err.response) {
                const errors = err.response?.data.errors;
                if (errors) {
                    Object.keys(errors).forEach((key) => {
                        document.getElementById(key).classList.add('is-invalid');
                        document.getElementById(key + 'Feedback').innerHTML = errors[key][0];
                    })
                }
            }
            Toastify({
                text: err.response?.data.message ?? err.response?.data.error ?? 'An error has occured. Please try again.',
                duration: 3000,
                gravity: "bottom",
                position: "center",
                backgroundColor: "#f44336",
                stopOnFocus: true,
                onClick: function () { },
                callback: function () { },
            }).showToast();
        }).finally(() => {
            console.log('Request completed.');
            document.getElementById('login_button').innerHTML = originalText;
        })

    }

    const onInputChange = (e) => {
        const { id, value } = e.target;
        if (id === 'email') {
            setEmail(value);
        } else if (id === 'password') {
            setPassword(value);
        }
        document.getElementById(id).classList.remove('is-invalid');
        document.getElementById(id + 'Feedback').innerHTML = '';
    }

    return (
        <>
            <Padding />
            <Background />
            <NavbarLogo />
            <Helmet>
                <style>{`
                    html, body {
                        padding-top: 20px;
                    }
                `}</style>
            </Helmet>
            <div className="container h-100">
                <div className="row h-100 align-items-center">
                    <div className="col-12 col-lg-7 d-none d-lg-block text-white pt-5">
                        <RegisterTerm />
                    </div>
                    <div className="col-12 col-lg-5">
                        <h1 className="mb-5 text-center"><span className="label-blue">WELCOME TO</span> <span className="label-red">KKU ICEM!</span></h1>
                        <div className="login-label mb-3 pe-3 ps-3 text-center">Login to view or edit your information.</div>

                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-12 col-md-8">
                                    <div className="form-group mb-3">
                                        <input type="email" className="form-control" placeholder="Email address" id="email" onChange={onInputChange} />
                                        <div className="invalid-feedback" id="emailFeedback"></div>
                                    </div>
                                    <div className="form-group mb-3">
                                        <input type="password" className="form-control" placeholder="Password" id="password" onChange={onInputChange} />
                                        <div className="invalid-feedback" id="passwordFeedback"></div>
                                    </div>

                                    <div className="row mb-3">
                                        <div className="col-6">
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" value="" id="remember_me" />
                                                <label className="form-check-label text-white" htmlFor="remember_me">
                                                    Remember me
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-6 text-end">
                                            <Link to="/forgot-password" className="text-decoration-none text-white">Forgot password?</Link>
                                        </div>
                                    </div>

                                    <div className="text-white mb-4">Don’t have an account? <Link to="/register" className="text-decoration text-white">Register</Link></div>

                                    <div className="d-flex justify-content-center">
                                        <div className="custom-button btn-custom-register" id="login_button" onClick={() => login()}>Login !</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div className="position-fixed bottom-0 end-0 p-3 w-100 d-block d-lg-none" style={{ zIndex: 11 }}>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-6">
                            <button className="btn btn-custom-register w-100" data-bs-toggle="modal" data-bs-target="#termsAndConditionsModal" style={{ fontSize: 12 }}>Terms and Conditions</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="termsAndConditionsModal" tabIndex="-1" aria-labelledby="termsAndConditionsModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
                    <div className="modal-content">
                        <div className="modal-body modal-custom-bg">
                            <h1 className="mb-5 text-center"><span className="label-blue">Terms and</span> <span className="label-red">Conditions</span></h1>
                            <ul className="text-white">
                                <li>To register, - Create an individual account for each member - Join as a team via a QR code generated by the team leader - Login and fill out the information - Required documents as listed below:
                                    <ul>
                                        <li>Passport information - Proof of enrollment - Transaction document</li>
                                        <li>Registration fees - Team (consisting of 3 members)</li>
                                    </ul>
                                </li>
                                <li>Early bird registration 15,000 THB/team</li>
                                <li>Regular registration 18,000 THB/team - Advisor (required) and observer</li>
                                <li>Early bird registration 4,000 THB/person</li>
                                <li>Regular registration 5,000 THB/person (with special program including trips and food)
                                    <ul>
                                        <li>Registration fees listed above DO NOT include any accommodation - You can choose your own preferred hotel rooms and pay accordingly via our website</li>
                                        <li>Payment beyond early-bird registration (December 5th 2023; 11.59 PM GMT +7.00) will be charged as a regular registration fee regardless of the prior date of application</li>
                                        <li>Personal information and documents can be edited throughout the registration period - We will contact you through the email registered on the team leader's account</li>
                                    </ul>
                                </li>
                            </ul>
                            <div className="mt-3 mb-3">
                                <div className="text-center text-danger">End of registration date: December 31st, 2023; 11.59 PM GMT +7.00</div>
                                <div className="text-center text-white">Should you have any question, do not hesitate to contact us via kkuicem.official@gmail.com</div>
                            </div>
                        </div>
                        <div className="modal-footer modal-custom-bg justify-content-center">
                            <button type="button" className="btn btn-custom-register" data-bs-dismiss="modal">Accept</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}