import axios from "axios";
import Toastify from "toastify-js";

export default function TeamMemberBox({ props }) {

    const removeMember = (e, id) => {
        e.preventDefault()

        const originalText = e.target.innerHTML;
        e.target.innerHTML = '<i class="fa-solid fa-circle-notch fa-spin text-white"></i>';
        e.target.disabled = true;

        axios.delete(`/api/teams/member/${id}`, {
        }).then((res) => {
            Toastify({
                text: res.data.message,
                duration: 3000,
                gravity: "bottom",
                position: 'center',
                backgroundColor: '#dc3545',
                className: 'info',
            }).showToast();
            window.location.reload();
        }).catch((err) => {
            console.log(err);
            Toastify({
                text: err.response.data.message,
                duration: 3000,
                gravity: "bottom",
                position: 'center',
                backgroundColor: '#dc3545',
                className: 'info',
            }).showToast();
        }).finally(() => {
            console.log('Request completed.');
            e.target.innerHTML = originalText;
            e.target.disabled = false;
        })
    }

    const markAsPaid = (e, id, flag) => {
        e.preventDefault()
        axios.patch(`/api/teams/member/${id}`, {
            is_paid: flag
        }).then((res) => {
            window.location.reload();   
        }).catch((err) => {
            console.log(err);
        }).finally(() => {
            console.log('Request completed.');
        })
    }

    return (
        <div style={{ border: '1px solid #fff', borderRadius: '10px' }} className="p-1 mb-2">
            <div>
            <div className="d-flex justify-content-between align-items-center p-1 w-100" >
                <div className="d-flex align-items-center">
                    <div className="me-3">
                        <img src={props.user.image_url} alt="avatar" className="img-fluid rounded-circle p-2" width={50} height={50} style={{ width: '50px', height: '50px' }} />
                    </div>
                    <div>
                        <div className="fw-bolder">{props.user.first_name} {props.user?.middle_name ?? ''} {props.user.last_name}</div>
                        <div className="text-white">{props.role.toUpperCase()}</div>
                    </div>
                </div>
                <div className="d-flex align-items-center">
                    {props.role !== 'leader' &&
                        <div className="ms-3 me-3">
                            <button className="btn btn-sm btn-danger" onClick={(e) => removeMember(e, props.user.id)}>
                                <i className="fa-solid fa-xmark"></i>
                            </button>
                        </div>
                    }
                </div>
                </div>
                {props.role === 'advisor' &&
                    <div className="me-2 ms-2">
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" value={props.is_paid} id={`flexCheckChecked${props.user.id}`} checked={props.is_paid === 'Y' ? true : false} onChange={(e) => markAsPaid(e, props.user.id, e.target.checked ? 'Y' : 'N')} />
                            <label class="form-check-label" for={`flexCheckChecked${props.user.id}`}>
                                <div style={{
                                    fontSize: '12px',
                                    color: '#fff'
                                }}>Mark as paid</div>
                                <div style={{
                                    fontSize: '10px',
                                    color: '#dc3545'
                                }}><small>Please ensure this advisor is already paid in other teams.</small></div>
                            </label>
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}