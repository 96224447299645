import { Helmet } from "react-helmet";
import background from '../assets/images/background.png';

export default function Background() {
    return (
        <Helmet>
            <style>
                {`
                    html, body {
                        background-image: url(${background});
                        background-repeat: no-repeat;
                        background-attachment: fixed;
                        background-size: cover;

                        height: 100vh;
                    }

                    #root {
                        height: 100vh;
                    }
                `}
            </style>
        </Helmet>
    )
}