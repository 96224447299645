export default function LeftComponetText({ props }) {
    return (
        <>
            <div className="row mb-4">
                <div className="col-12 col-lg-7 ps-lg-5 pe-lg-5 order-2 order-lg-1">
                    {props.content}
                </div>
                <div className="col-12 col-lg-5 ps-lg-5 pe-lg-5 order-1 order-lg-2">
                    {props.image}
                </div>
            </div>
        </>
    )
}