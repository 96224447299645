import { useEffect, useState } from "react";
import BackgroundV2 from "../../components/BackgroundV2";
import NavbarLogo from "../../components/NavbarLogo";
import { Helmet } from "react-helmet";
import Timer from "easytimer.js";
import axios from "axios";
import { Link } from "react-router-dom";
import Toastify from 'toastify-js'
import _ from "lodash";
import Preload from "../../components/Preload";
import { QrScanner } from '@yudiel/react-qr-scanner';
import LabelState from "../../components/LabelState";
import dayjs from "dayjs";

export default function Lobby() {

    const [userInfo, setUserInfo] = useState({});
    const [code, setCode] = useState('');
    const [scanning, setScanning] = useState(false);
    const [joining, setJoining] = useState(false);
    const [teamId, setTeamId] = useState(null);

    useEffect(() => {
        getUserInfo();
    }, [])

    const countDown = (seconds) => {
        var timer = new Timer();
        timer.start({ countdown: true, startValues: { seconds: seconds } });
        timer.addEventListener('secondsUpdated', function (e) {
            const days = document?.querySelector('.days')
            if (days) {
                days.innerHTML = timer.getTimeValues().days;

                if (days.innerHTML.length === 1) {
                    days.innerHTML = '0' + days.innerHTML;
                }
            }

            const hours = document?.querySelector('.hours')
            if (hours) {
                // starting with 0
                hours.innerHTML = timer.getTimeValues().hours;
                if (hours.innerHTML.length === 1) {
                    hours.innerHTML = '0' + hours.innerHTML;
                }
            }

            const minutes = document?.querySelector('.minutes')
            if (minutes) {
                minutes.innerHTML = timer.getTimeValues().minutes;

                if (minutes.innerHTML.length === 1) {
                    minutes.innerHTML = '0' + minutes.innerHTML;
                }
            }
        });
    }

    const getUserInfo = () => {
        const token = localStorage.getItem('access_token');
        axios.get('/api/auth/me', { token }).then((res) => {
            setUserInfo(res.data);
            console.log(res.data)

            if (res.data.role === 'advisor') {
                if (res.data.team_member.length > 0) {
                    setTeamId(res.data.team_member[0].team.id);
                }
            } else {
                if (res.data?.team || res.data?.team_member) {
                    setTeamId(res.data?.team?.id || res.data.team_member?.team?.id);
                }
            }


            const last_due_date = res.data?.team_member?.team?.last_due_date || res.data?.team?.last_due_date;
            const teamTransactions = res.data?.team_member?.team?.transactions || res.data?.team?.transactions;
            console.log(last_due_date)

            const now = dayjs().format('YYYY-MM-DD HH:mm:ss');
            const diff = dayjs(last_due_date).diff(now, 'second');

            if (teamTransactions?.status === 'pending') {
                countDown(diff);
            }


        }).catch((err) => {
            console.log(err);
        }).finally(() => {
            console.log('Request completed.');
            const modal = new window.bootstrap.Modal(document.getElementById('modal-lobby'), {
                keyboard: false
            });
            modal.show();
        })
    }

    const joinByCode = (e) => {
        e.target.disabled = true;
        const buttonContent = e.target.innerHTML;
        e.target.innerHTML = '<i class="fa-solid fa-circle-notch fa-spin"></i> Joining...';

        if (_.isEmpty(code)) {
            Toastify({
                text: "Invalid code.",
                duration: 3000,
                gravity: "bottom",
                position: "center",
                backgroundColor: "#f44336",
                stopOnFocus: true,
            }).showToast();
            e.target.disabled = false;
            e.target.innerHTML = buttonContent;
            return;
        }

        axios.post('/api/teams/join', { code }).then((res) => {
            console.log(res.data);
            Toastify({
                text: "Join team successfully.",
                duration: 3000,
                gravity: "bottom",
                position: "center",
                backgroundColor: "#4caf50",
                stopOnFocus: true,
            }).showToast();
            return window.location.href = '/team/manage/' + res.data.data.id;
        }).catch((err) => {
            console.log(err);
            if (err.response && err.response.data.message) {
                Toastify({
                    text: err.response.data.message,
                    duration: 3000,
                    gravity: "bottom",
                    position: "center",
                    backgroundColor: "#f44336",
                    stopOnFocus: true,
                }).showToast();
            }
        }).finally(() => {
            console.log('Request completed.');
            e.target.disabled = false;
            e.target.innerHTML = buttonContent;
        })
    }

    const join = (code) => {
        if (_.isEmpty(code)) {
            return;
        }
        setJoining(true);
        axios.post('/api/teams/join', { code }).then((res) => {
            console.log(res.data);
            Toastify({
                text: "Join team successfully.",
                duration: 3000,
                gravity: "bottom",
                position: "center",
                backgroundColor: "#4caf50",
                stopOnFocus: true,
            }).showToast();
            return window.location.href = '/team/manage/' + res.data.data.id;
        }).catch((err) => {
            if (err.response && err.response.data.message) {
                Toastify({
                    text: err.response.data.message,
                    duration: 3000,
                    gravity: "bottom",
                    position: "center",
                    backgroundColor: "#f44336",
                    stopOnFocus: true,
                    onClick: function () {
                        if (err?.response?.data?.message === 'Please complete your profile first.') {
                            window.location.href = '/profile';
                        }
                    },
                    callback: function () {
                        if (err?.response?.data?.message === 'Please complete your profile first.') {
                            window.location.href = '/profile';
                        }
                    },
                }).showToast();
            }
        }).finally(() => {
            console.log('Request completed.');
            setJoining(false);
        })
    }

    const requiredUpdateProfile = () => {
        if (!userInfo?.profile) {
            return Toastify({
                text: "Please complete your profile first.",
                duration: 3000,
                gravity: "bottom",
                position: "center",
                backgroundColor: "#f44336",
                stopOnFocus: true,
            }).showToast();
        }
    }

    return (
        <>
            <BackgroundV2 props={{ height: '75vh' }} />
            <NavbarLogo />

            <Helmet>
                <style>{`
                    html, body {
                        padding-top: 75px;
                    }
                `}</style>
            </Helmet>

            {!_?.isEmpty(userInfo) ?
                <>
                    <div className="container">
                        <div className="row">

                            <div className="col-12 col-lg-6">
                                <div className="d-flex user-info-box">
                                    <div className="me-3">
                                        <img src={userInfo?.image_url} alt="avatar" className="img-fluid rounded-circle p-2" width={80} />
                                    </div>
                                    <div className="d-flex">
                                        <div className="d-flex flex-column justify-content-center">
                                            <h4 className="text-start text-white">Hi, {userInfo?.first_name}</h4>
                                            <div className="text-white">Welcome to KKUICEM 2024</div>
                                            <Link to="/profile" className="text-decoration-none">
                                                <button className="btn btn-sm btn-outline-light mt-2">Update profile</button>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                {(userInfo?.team?.last_due_date || userInfo?.team_member?.team?.last_due_date) ?
                                    <ul className="text-start text-white mt-4">
                                        <li>The payment must be done within 10 days after the account has been registered</li>
                                        <li>If the payment is delayed, this account will be deleted</li>
                                        <li>Personal information can still be edited after the payment has been confirmed</li>
                                    </ul> : null}
                            </div>

                            <div className="col-12 col-lg-6">
                                {(userInfo?.team?.last_due_date || userInfo?.team_member?.team?.last_due_date ) ?
                                    <div className="card transparent-bg">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-12 col-lg-6 mb-3">
                                                    <div className="d-flex justify-content-evenly align-items-center text-white">
                                                        <div>
                                                            <h1 className="days">NA</h1>
                                                            <div>Days</div>
                                                        </div>
                                                        <div className="ms-3">
                                                            <h1 className="hours">NA</h1>
                                                            <div>Hours</div>
                                                        </div>
                                                        <div className="ms-3">
                                                            <h1 className="minutes">NA</h1>
                                                            <div>Minutes</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-lg-6 mb-3">

                                                    <div className="w-100">
                                                        <LabelState props={{
                                                            status: userInfo?.team_member ? userInfo?.team_member?.team?.transactions?.status ?? 'pending' : userInfo?.team?.transactions?.status ?? 'pending',
                                                            text: userInfo?.team_member ? userInfo?.team_member?.team?.transactions?.status ?? 'pending' : userInfo?.team?.transactions?.status ?? 'pending'
                                                        }} />
                                                    </div>
                                                </div>

                                            </div>
                                            <h4 className="text-white mt-2 fw-bolder text-center">LEFT UNTIL PAYMENT IS DUE</h4>
                                        </div>
                                    </div> : null}
                            </div>
                        </div>
                    </div>

                    <div className="container mt-1 mb-5">
                        <div className="row">
                            <div className="col-12">
                                <hr className="text-white" />
                            </div>
                        </div>
                    </div>

                    <div className="container pb-5">
                        <div className="row justify-content-center align-items-center">
                            <div className="col-12 col-lg-4 mb-4">
                                <div className="card modal-custom-bg h-100">
                                    <div className="card-body">
                                        <h5 className="box-lobby card-title text-white"><i className="fa-solid fa-users"></i> Team Information</h5>

                                        <div className="alert alert-secondary mt-3 text-center" role="alert">
                                            {userInfo?.team || userInfo?.team_member ?
                                                <>
                                                    {userInfo?.role === 'advisor' ?
                                                        <div className="form-group">
                                                            <select className="form-control form-select p-0 m-0 text-center" onChange={(e) => setTeamId(e.target.value)}>
                                                                {userInfo?.team_member?.map((teamMember, index) => {
                                                                    return (
                                                                        <option key={index} value={teamMember?.team.id}>{teamMember?.team?.team_name}</option>
                                                                    )
                                                                }
                                                                )}
                                                            </select>
                                                        </div>
                                                        :
                                                        <div>{userInfo?.team?.team_name || userInfo?.team_member?.team?.team_name}</div>
                                                    }
                                                </>
                                                :
                                                <div><i className="fa-solid fa-circle-info fa-1x"></i> Please join or create a team.</div>
                                            }
                                        </div>

                                        <div className="row justify-content-center">

                                            {(!userInfo?.team && !userInfo?.team_member) || userInfo?.role === 'advisor' ?
                                                <div className="col-12 col-md-6 mb-3 mb-md-0">
                                                    <button className="btn yellow-neon-button w-100" data-bs-toggle="modal" data-bs-target="#joinTeam">Join team</button>
                                                </div>
                                                : null
                                            }

                                            <div className={`col-12 ${userInfo?.role === 'advisor' ? 'col-md-6' : userInfo?.team || userInfo?.teamMember ? '' : 'col-md-6'}`} >
                                                {userInfo?.team || userInfo?.team_member ?
                                                    <Link to={`/team/manage/${teamId}`} className="text-decoration-none">
                                                        <button className="btn red-neon-button w-100">
                                                            {userInfo?.team ? 'Manage your team' : 'View'}
                                                        </button>
                                                    </Link>
                                                    :
                                                    <Link to="/team/create" className="text-decoration-none">
                                                        <button className="btn red-neon-button w-100">Create your team</button>
                                                    </Link>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 col-lg-4 mb-4">
                                <div className="card modal-custom-bg h-100">
                                    <div className="card-body">
                                        <h5 className="box-lobby card-title text-white"><i className="fa-regular fa-address-card"></i> Personal Document</h5>
                                        <div className="alert alert-secondary mt-3 text-center" role="alert">
                                            {!userInfo?.verification.passport ?
                                                <div><i className="fa-solid fa-circle-info fa-1x"></i> Please upload your personal document.</div>
                                                :
                                                <div><i className="fa-solid fa-circle-check fa-1x"></i> Your document has been uploaded.</div>
                                            }
                                        </div>

                                        <div className="row">
                                            <div className="col-12">
                                                {userInfo?.profile ?
                                                    <Link to='/verification' className="text-decoration-none">
                                                        <button className="btn yellow-neon-button w-100">Make verification</button>
                                                    </Link>
                                                    :
                                                    <button className="btn yellow-neon-button w-100" onClick={requiredUpdateProfile}>Make verification</button>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 col-lg-4 mb-4">
                                <div className="card modal-custom-bg h-100">
                                    <div className="card-body">
                                        <h5 className="box-lobby card-title text-white"><i className="fa-solid fa-martini-glass-citrus"></i> Trips and souvenirs</h5>
                                        <div className="alert alert-secondary mt-3 text-center" role="alert">
                                            {!userInfo?.trip ?
                                                <div><i className="fa-solid fa-circle-info fa-1x"></i> Please choose your trip and souvenirs.</div>
                                                :
                                                <div><i className="fa-solid fa-circle-check fa-1x"></i> Your trip and souvenirs have been chosen.</div>
                                            }
                                        </div>

                                        <div className="row">
                                            <div className="col-12">
                                                {userInfo?.profile ?
                                                    <Link to='/trips-and-souvenirs' className="text-decoration-none">
                                                        <button className="btn yellow-neon-button w-100">Choose</button>
                                                    </Link>
                                                    :
                                                    <button className="btn yellow-neon-button w-100" onClick={requiredUpdateProfile}>Choose</button>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className="row mt-3 mb-4">
                            <div className="col-12 text-center mt-3">
                                <Link to="/" className="text-white">Home</Link>
                            </div>
                        </div>
                    </div>

                    <div className="modal fade" id="joinTeam" tabIndex="-1" aria-labelledby="joinTeam" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-body modal-custom-bg">
                                    <h4 className="mb-5 text-center"><span className="label-red">Join team</span></h4>

                                    {!scanning ?
                                        <>
                                            <div className="mb-3">
                                                <input type="text" className="form-control" id="code" placeholder="Enter team code or link here" onChange={(e) => setCode(e.target.value)} />
                                            </div>
                                            <button className="btn btn-outline-light w-100" onClick={(e) => joinByCode(e)}>Join</button>
                                            <button className="btn yellow-neon-button w-100 mt-2 mb-5" onClick={() => setScanning(true)}>Scan QR Code</button>

                                            <button type="button" className="btn btn-custom-register w-100" data-bs-dismiss="modal">Cancel</button>
                                        </>
                                        :
                                        <>
                                            {!joining ?
                                                <QrScanner
                                                    onDecode={(result) => !joining && join(result)}
                                                    onError={(error) => console.log(error?.message)}
                                                />
                                                :
                                                <div className="mb-3 mt-3">
                                                    <Preload />
                                                </div>
                                            }
                                            <button className="btn btn-custom-register w-100 mt-2 mb-5" onClick={() => setScanning(false)}>Cancel</button>
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="modal fade" id="modal-lobby" tabIndex="-1" aria-labelledby="modal-lobby" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
                            <div className="modal-content">
                                <div className="modal-body modal-custom-bg">
                                    <h4 className="mb-5 text-center"><span className="label-red"><i className="fa-solid fa-circle-info"></i> Please read this information first !</span></h4>
                                    <div className="text-white p-3">
                                        <div className="fw-bold">Team</div>
                                        <div>- A team consists of 3 members who are currently medical students</div>
                                        <div>- Each team is allowed to have only one advisor</div>
                                        <div>- An institution is allowed to have the maximum number of 3 teams</div>
                                        <div>- Each team can have an unlimited number of observers</div>
                                        <div>- Advisors and observers are not allowed to partake in competition rounds but can join other activities</div>
                                    </div>
                                </div>
                                <div className="modal-footer modal-custom-bg justify-content-center">
                                    <button type="button" className="btn btn-custom-register" data-bs-dismiss="modal">Confirm</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
                :
                <Preload />
            }
        </>
    )
}
