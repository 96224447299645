import { useCallback, useState } from 'react';
import { useResizeObserver } from '@wojtekmaj/react-hooks';
import { pdfjs, Document, Page } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import competitionGuidebook from '../assets/docs/competition_guidebook_v1.5.pdf';
import Background from '../components/Background';
import { Link } from 'react-router-dom';
import Navbar from '../components/Navbar';
import Preload from '../components/Preload';

const url = `//cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`
pdfjs.GlobalWorkerOptions.workerSrc = url

const options = {
    cMapUrl: '/cmaps/',
    standardFontDataUrl: '/standard_fonts/',
};

const resizeObserverOptions = {};

const maxWidth = 435;

export default function Guidebook() {
    const [pageNumber, setPageNumber] = useState(1);
    const [numberOfPages, setNumberOfPages] = useState(null);
    const [containerRef, setContainerRef] = useState(null);
    const [containerWidth, setContainerWidth] = useState();

    const onResize = useCallback((entries) => {
        const [entry] = entries;

        if (entry) {
            setContainerWidth(entry.contentRect.width);
        }
    }, []);

    useResizeObserver(containerRef, resizeObserverOptions, onResize);

    function onDocumentLoadSuccess(data) {
        const numPages = data._pdfInfo.numPages;
        setNumberOfPages(numPages);
    }

    function onPrevPage() {
        if (pageNumber > 1) {
            setPageNumber(pageNumber - 1);
        }
    }

    function onNextPage() {
        if (pageNumber < numberOfPages) {
            setPageNumber(pageNumber + 1);
        }
    }

    return (
        <div className="d-flex justify-content-center h-100 align-items-center" ref={setContainerRef}>
            <Navbar />
            <Background />
            <Document file={competitionGuidebook} onLoadSuccess={onDocumentLoadSuccess} options={options} loading={<Preload />}>
                <Page pageNumber={pageNumber} width={containerWidth > maxWidth ? maxWidth : containerWidth} />
            </Document>
            <div className="position-absolute w-100 bottom-0 mb-4" style={{ zIndex: 100 }}>
                <div className="d-flex justify-content-center">
                    <a className="btn btn-outline-light me-3" href={competitionGuidebook} download="competition_guidebook_v1.5.pdf"><i className="fa-solid fa-cloud-arrow-down"></i></a>
                    <div className="btn-group me-3">
                        <button type="button" className="btn btn-outline-light" disabled={pageNumber <= 1} onClick={onPrevPage}>
                            <i className="fa-solid fa-chevron-left"></i>
                        </button>
                        <span className="btn btn-outline-light">
                            Page {pageNumber} of {numberOfPages}
                        </span>
                        <button type="button" className="btn btn-outline-light" disabled={pageNumber >= numberOfPages} onClick={onNextPage}>
                            <i className="fa-solid fa-chevron-right"></i>
                        </button>
                    </div>
                    <Link to="/" className="btn btn-outline-light"><i className="fa-solid fa-home"></i></Link>
                </div>
            </div>
        </div>
    );
}