export default function RightComponetText({ props }) {
    return (
        <>
            <div className="row mb-4">
                <div className="col-12 col-lg-5 ps-lg-5 pe-lg-5">
                    {props.image}
                </div>
                <div className="col-12 col-lg-7">
                    {props.content}
                </div>
            </div>
        </>
    )
}