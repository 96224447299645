import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';

import image1 from '../assets/images/gallery/gallery-1.png';
import image2 from '../assets/images/gallery/gallery-2.png';
import image3 from '../assets/images/gallery/gallery-3.png';
import image4 from '../assets/images/gallery/gallery-4.png';
import image5 from '../assets/images/gallery/gallery-5.png';
import image6 from '../assets/images/gallery/gallery-6.png';
import image7 from '../assets/images/gallery/gallery-7.png';

export default function Gallery() {
    return (
        <div className='container mb-4'>
            <div className='row'>
                <div className='col-12'>
                    <div className='text-center mb-4 gallery'>
                        <h1 className='label-blue'>Gallery</h1>
                    </div>
                    <div className='card gallery-bg neon-red gallery-border '>
                        <div className='card-body p-5 position-relative'>
                            <Splide aria-label="Gallery"
                                options={{
                                    perPage: 3,
                                    perMove: 1,
                                    autoplay: true,
                                    type: 'loop',
                                    breakpoints: {
                                        768: {
                                            perPage: 1,
                                            perMove: 1,
                                        }
                                    },
                                    gap: '0.5rem',
                                }}
                                className='row'
                            >
                                <SplideSlide className='text-center col-4'>
                                    <img src={image1} alt="Gallery 1" className='img-fluid' />
                                </SplideSlide>
                                <SplideSlide className='text-center col-4'>
                                    <img src={image2} alt="Gallery 2" className='img-fluid' />
                                </SplideSlide>
                                <SplideSlide className='text-center col-4'>
                                    <img src={image3} alt="Gallery 3" className='img-fluid' />
                                </SplideSlide>
                                <SplideSlide className='text-center col-4'>
                                    <img src={image4} alt="Gallery 1" className='img-fluid' />
                                </SplideSlide>
                                <SplideSlide className='text-center col-4'>
                                    <img src={image5} alt="Gallery 2" className='img-fluid' />
                                </SplideSlide>
                                <SplideSlide className='text-center col-4'>
                                    <img src={image6} alt="Gallery 3" className='img-fluid' />
                                </SplideSlide>
                                <SplideSlide className='text-center col-4'>
                                    <img src={image7} alt="Gallery 3" className='img-fluid' />
                                </SplideSlide>

                                {/* custom arrows */}

                            </Splide>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}