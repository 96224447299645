import { Link } from "react-router-dom";
import BackgroundV2 from "../../components/BackgroundV2";
import NavbarLogo from "../../components/NavbarLogo";
import { Helmet } from "react-helmet";
import { useEffect, useState } from "react";
import axios from "axios";
import Toastify from "toastify-js";
import Preload from "../../components/Preload";

export default function Verification() {

    const [passport, setPassport] = useState(null);
    const [proof, setProof] = useState(null);
    const [data, setData] = useState(null);
    const [userInfo, setUserInfo] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        Promise.all([getUserInfo(), getVerification(), setLoading(false)]);
    }, [])

    const getUserInfo = () => {
        const token = localStorage.getItem('access_token');
        axios.get('/api/auth/me', { token }).then((res) => {
            setUserInfo(res.data);
            console.log(res.data)
        }).catch((err) => {
            console.log(err);
        }).finally(() => {
            console.log('Request completed.');
        })
    }

    const handlePassport = (e) => {
        setPassport(e.target.files[0]);
        document.getElementById('passport-name').innerHTML = e.target.files[0].name;
    }

    const handleProof = (e) => {
        setProof(e.target.files[0]);
        document.getElementById('proof-name').innerHTML = e.target.files[0].name;
    }

    const save = (e) => {

        // disable button
        e.target.disabled = true;
        const originalText = e.target.innerHTML;
        e.target.innerHTML = 'Updating...';

        const formData = new FormData();
        formData.append('passport', passport);
        formData.append('proof', proof);

        axios.post('/api/verifications/save', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then((res) => {
            Toastify({
                text: 'Personal documents updated.',
                duration: 3000,
                gravity: "bottom",
                position: "center",
                backgroundColor: "#064B72",
                stopOnFocus: true,
                onClick: function () { },
                callback: function () { },
            }).showToast();
        }).catch((err) => {
            Toastify({
                text: err?.response?.data?.message ?? 'An error occured.',
                duration: 3000,
                gravity: "bottom",
                position: "center",
                backgroundColor: "#FF0000",
                stopOnFocus: true,
                onClick: function () { },
                callback: function () { },
            })
        }).finally(() => {
            e.target.disabled = false;
            e.target.innerHTML = originalText;
        })
    }

    const getVerification = () => {
        axios.get('/api/verifications').then((res) => {
            setData(res.data.data);
            if (res.data?.data?.passport) {
                document.getElementById('passport-name').innerHTML = res.data?.passport;
            }
            if (res.data?.data?.proof_of_enrollment) {
                document.getElementById('proof-name').innerHTML = res.data?.proof;
            }
        }).catch((err) => {
            console.log(err);
        })
    }

    return (
        <>
            <BackgroundV2 props={{ height: '75vh' }} />
            <NavbarLogo />

            <Helmet>
                <style>{`
                    html, body {
                        padding-top: 75px;
                    }
                `}</style>
            </Helmet>

            {!loading ?
            <div className="container pb-5">
                <div className="row mb-4 justify-content-center align-items-center">
                    <div className="col-12 col-lg-6 mb-4">
                        <h5 className="text-white text-center">Passport</h5>
                        <div className="text-center text-white">Data page of passport which includes your picture, name, date of birth, etc.</div>

                        <div className="card mt-4">
                            <label className="card-body text-center d-flex justify-content-center align-items-center flex-column" style={{ height: 300, cursor: 'pointer' }} for="passport">
                                <i class="fa-solid fa-file-arrow-up" style={{ fontSize: 120 }}></i>
                                <div className="text-center text-dark mt-3">Drag file to upload</div>
                                <input className="form-control" type="file" id="passport" hidden onChange={handlePassport} />
                            </label>
                            <label className="card-footer text-center p-3" style={{ color: "#064B72", cursor: 'pointer' }} for="passport">
                                Choose file to upload
                            </label>
                        </div>

                        <div className="card mt-4">
                            <div className="card-body">
                                <Link to={data ? data?.passport : '#'} {...(data?.passport ? { target: "_blank" } : {})} rel="noreferrer">
                                    <div className="d-flex justify-content-between">
                                        <div><i class="fa-regular fa-file-lines"></i></div>
                                        <div className="text-end" id="passport-name">
                                        <i class="fa-solid fa-circle-notch fa-spin"></i>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>

                    {userInfo?.role === 'student' &&
                    <div className="col-12 col-lg-6 mb-4 text-center">
                        <h5 className="text-white text-center">Proof of enrollment</h5>
                        <di className="text-center text-white">A document that proves that you are a student.</di>
                        <div className="card mt-4">
                            <label className="card-body text-center d-flex justify-content-center align-items-center flex-column" style={{ height: 300, cursor: 'pointer' }} for="proof">
                                <i class="fa-solid fa-file-arrow-up" style={{ fontSize: 120 }}></i>
                                <div className="text-center text-dark mt-3">Drag file to upload</div>
                                <input className="form-control" type="file" id="proof" hidden onChange={handleProof} />
                            </label>
                            <label className="card-footer text-center p-3" style={{ color: "#064B72", cursor: 'pointer' }} for="proof">
                                Choose file to upload
                            </label>
                        </div>

                        <div className="card mt-4">
                            <div className="card-body">
                                <Link to={data ? data?.proof_of_enrollment : '#'} {...(data?.proof_of_enrollment ? { target: "_blank" } : {})} rel="noreferrer">
                                    <div className="d-flex justify-content-between">
                                        <div><i class="fa-regular fa-file-lines"></i></div>
                                        <div className="text-end" id="proof-name">
                                        <i class="fa-solid fa-circle-notch fa-spin"></i>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                    }
                </div>

                <div className="row mb-4 mt-3">
                    <div className="col-12 text-center">
                        <button className="btn btn-custom-register" onClick={save}>
                            Save changes
                        </button>
                    </div>
                    <div className="col-12 text-center mt-3">
                        <Link to="/lobby" className="text-white">Go to lobby</Link>
                    </div>
                </div>
            </div>
            :
            <Preload />
            }
        </>
    )
}