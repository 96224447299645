import RegisterButton from "./RegisterButton";
import LoginButton from "./LoginButton";

export default function FloatingAuth() {
    return (
        <>
            <RegisterButton />
            <LoginButton />
        </>
    )
}