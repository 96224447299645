import LogoutButton from "./LogoutButton";
import LobbyButton from "./LobbyButton";

export default function FloatingAuthenticated() {
    return (
        <>
            <LobbyButton />
            <LogoutButton />
        </>
    )
}