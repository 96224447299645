import Background from "../components/Background";
import NavbarLogo from "../components/NavbarLogo";
import { Link, useParams } from "react-router-dom";
import Padding from "../components/Padding";
import { useEffect, useState } from "react";
import axios from "axios";
import Preload from "../components/Preload";
import { resetPasswordSchema } from "../schema/reset-password.schema";
import Toastify from "toastify-js";

export default function ResetPassword() {

    const [loading, setLoading] = useState(true);
    const [validToken, setValidToken] = useState(false);
    const { token } = useParams();

    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    useEffect(() => {
        verifyToken();
    }, [])

    const verifyToken = () => {
        axios.get('/api/verify-token/' + token).then((res) => {
            if (res.data.status === 'success') {
                setValidToken(true);
                return;
            }
            setValidToken(false);
        }).catch((err) => {
            console.log(err);
        }).finally(() => {
            setLoading(false);
        })
    }

    const resetPassword = () => {

        const originalText = document.getElementById('resetpassword').innerHTML;
        document.getElementById('resetpassword').innerHTML = '<i class="fa-solid fa-circle-notch fa-spin text-white"></i>';

        const payload = {
            token: token,
            password: password,
            confirm_password: confirmPassword
        }

        const validate = resetPasswordSchema.validate(payload, { abortEarly: false });
        if (validate?.error) {
            validate.error.details.forEach((err) => {
                if (err.context.key === 'token') {

                } else {
                    document.getElementById(err.context.key).classList.add('is-invalid');
                    document.getElementById(err.context.key + 'Feedback').innerHTML = err.message;
                }
            })
            document.getElementById('resetpassword').innerHTML = originalText;
            return;
        }

        axios.post('/api/reset-password', payload).then((res) => {
            if (res.data.status === 'success') {
                Toastify({
                    text: "Your password has been reset successfully.",
                    duration: 3000,
                    gravity: "bottom",
                    position: "center",
                    backgroundColor: "#4caf50",
                    stopOnFocus: true,
                    onClick: function () {
                        window.location.href = '/login';
                    },
                    callback: function () {
                        window.location.href = '/login';
                    },
                }).showToast();
            }
        }).catch((err) => {
            if (err.response) {
                const errors = err.response.data.errors;
                if (errors) {
                    Object.keys(errors).forEach((key) => {
                        document.getElementById(key).classList.add('is-invalid');
                        document.getElementById(key + 'Feedback').innerHTML = errors[key][0];
                    })
                }
            }
        }).finally(() => {
            console.log('Request completed.');
            document.getElementById('resetpassword').innerHTML = originalText;
        })
    }

    const onInputChange = (e) => {
        const { id, value } = e.target;
        if (id === 'password') {
            setPassword(value);
            document.getElementById('password').classList.remove('is-invalid');
        } else if (id === 'confirm_password') {
            setConfirmPassword(value);
            document.getElementById('confirm_password').classList.remove('is-invalid');
        }
    }

    return (
        <>
            <Padding />
            <Background />
            <NavbarLogo />
            {!loading ?
                <>
                    {validToken ?
                        <div className="d-flex justify-content-center h-100 align-items-center flex-column">
                            <h1 className="mb-5 text-center"><span className="label-blue">Create your</span> <span className="label-red">new password</span></h1>
                            <div className="login-label mb-3 pe-3 ps-3 text-center">Choose a new password for your account.</div>

                            <div className="container">
                                <div className="row justify-content-center">
                                    <div className="col-12 col-md-6 col-lg-4">
                                        <div className="form-group mb-3">
                                            <input type="password" className="form-control" placeholder="Password" id="password" onChange={onInputChange} />
                                            <div className="invalid-feedback" id="passwordFeedback"></div>
                                        </div>
                                        <div className="form-group mb-3">
                                            <input type="password" className="form-control" placeholder="Confirm password" id="confirm_password" onChange={onInputChange} />
                                            <div className="invalid-feedback" id="confirm_passwordFeedback"></div>
                                        </div>

                                        <div className="d-flex justify-content-center mb-4">
                                            <div className="custom-button btn-custom-register" id="resetpassword" onClick={resetPassword}>Confirm</div>
                                        </div>

                                        <div className="text-white mb-4 text-center">Back to <Link to="/login" className="text-decoration text-white">Login</Link> ?</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        <div className="d-flex justify-content-center h-100 align-items-center flex-column">
                            <h1 className="mb-5 text-center"><span className="label-red">Invaild or expired link</span></h1>
                            <div className="login-label mb-3 pe-3 ps-3 text-center">The link you followed may be broken, or the page may have been removed.</div>

                            <div className="container">
                                <div className="row justify-content-center">
                                    <div className="col-12 col-md-6 col-lg-4">
                                        <div className="text-white mb-4 text-center">Back to <Link to="/login" className="text-decoration text-white">Login</Link> ?</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </>
                :

                <Preload />

            }
        </>
    )
}