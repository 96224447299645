import BackgroundV2 from "../../components/BackgroundV2";
import NavbarLogo from "../../components/NavbarLogo";
import { Helmet } from "react-helmet";

import { useEffect, useState } from "react";
import { Splide, SplideSlide } from '@splidejs/react-splide';
import { Link } from "react-router-dom";
import axios from "axios";
import Toastify from "toastify-js";
import Swal from "sweetalert2";
import { useParams } from "react-router-dom";

export default function Accommodations() {

    const [hotel, setHotel] = useState('bayasita');
    const [roomType, setRoomType] = useState(null);
    const [numberOfRooms, setNumberOfRooms] = useState(0);
    const [numberOfExtraBeds, setNumberOfExtraBeds] = useState(0);
    const [data, setData] = useState(null);
    const [dataB, setDataB] = useState(null);
    const [dataX, setDataX] = useState(null);
    const [extraBedPrice, setExtraBedPrice] = useState(450);

    const [feePrice, setFeePrice] = useState(0);
    const [advisorAndObserverFee, setAdvisorAndObserverFee] = useState(0);
    const [advisorPrice, setAdvisorPrice] = useState(0);
    const [advisorCount, setAdvisorCount] = useState(0);
    const [observerPrice, setObserverPrice] = useState(0);
    const [observerCount, setObserverCount] = useState(0);
    const [initialPrice, setInitialPrice] = useState(0);
    const [unpaidAdvisor, setUnpaidAdvisor] = useState(0);
    const [paidAdvisor, setPaidAdvisor] = useState(0);

    const [roomPrice, setRoomPrice] = useState(0);

    const { teamId } = useParams();

    useEffect(() => {
        getHotel('bayasita');
        getBayasita();
        getXotel();
    }, [])

    const BAYASITA_IMAGES = [
        'https://pub-3d6ffa377ce448019676a38678d8594b.r2.dev/bayasita/2.jpg',
        'https://pub-3d6ffa377ce448019676a38678d8594b.r2.dev/bayasita/3.jpg',
        'https://pub-3d6ffa377ce448019676a38678d8594b.r2.dev/bayasita/4.jpg',
    ]
    const XOTEL_IMAGES = [
        'https://pub-3d6ffa377ce448019676a38678d8594b.r2.dev/xotel/2.jpg',
        'https://pub-3d6ffa377ce448019676a38678d8594b.r2.dev/xotel/4.jpg',
        'https://pub-3d6ffa377ce448019676a38678d8594b.r2.dev/xotel/5.jpg',
        'https://pub-3d6ffa377ce448019676a38678d8594b.r2.dev/xotel/6.jpg',
        'https://pub-3d6ffa377ce448019676a38678d8594b.r2.dev/xotel/7.jpg',
        'https://pub-3d6ffa377ce448019676a38678d8594b.r2.dev/xotel/8.jpg',
    ]

    const getHotel = (hotel) => {
        axios.post('/api/hotel', {
            hotel: hotel,
            team_id: teamId
        }).then((res) => {
            console.log(res.data);
            setData(res.data.data);
            setFeePrice(res.data.data.fee);
            setAdvisorAndObserverFee(res.data.data.advisor_and_observer_fee);
            setAdvisorCount(res.data.data.advisors_count);
            setObserverCount(res.data.data.observers_count);
            setObserverPrice(res.data.data.observers_price);
            setAdvisorPrice(res.data.data.advisors_price);
            setInitialPrice(res.data.data.advisors_price + res.data.data.observers_price + res.data.data.fee);
            setUnpaidAdvisor(res.data.data.unpaid_advisors_count);
            setPaidAdvisor(res.data.data.paid_advisors_count);
        }).catch((err) => {
            console.log(err);
        })
    }

    const getBayasita = () => {
        axios.post('/api/hotel', {
            hotel: 'bayasita',
            team_id: teamId
        }).then((res) => {
            console.log(res.data);
            setDataB(res.data.data);
        }).catch((err) => {
            console.log(err);
        })
    }

    const getXotel = () => {
        axios.post('/api/hotel', {
            hotel: 'xotel',
            team_id: teamId
        }).then((res) => {
            console.log(res.data);
            setDataX(res.data.data);
        }).catch((err) => {
            console.log(err);
        })
    }

    const numberFormat = (number) => {
        return number.toLocaleString({
            style: 'currency',
            currency: 'THB',
            currencyDisplay: 'code'
        })
    }

    const renderConfirmation = () => {
        return (
            <div>
                {/* <div className="row mt-3 mb-2">
                    <div className="col-12">Registration Fee: {numberFormat(feePrice)} THB</div>
                    <div className="col-12">Advisor Fee: {unpaidAdvisor} x {numberFormat(advisorAndObserverFee)} = {numberFormat(advisorPrice)} THB</div>
                    <div className="col-12">Observer Fee: {observerCount} x {numberFormat(advisorAndObserverFee)} = {numberFormat(observerPrice)} THB</div>

                    {paidAdvisor > 0 ? <div className="col-12">Paid Advisor: 1</div> : null}
                    {advisorCount > 0 ? <div className="col-12">Total Advisor: {advisorCount}</div> : null}
            
                </div> */}
                <div className="row">
                    <div className="col-6">
                        <h4>Total: {numberFormat((roomPrice * numberOfRooms) + (numberOfExtraBeds * (450 * 5)))} THB</h4>
                    </div>
                    <div className="col-6 text-end">
                        <button className="btn btn-outline-secondary"
                            id="book"
                            onClick={() => book()}
                            disabled={
                                numberOfRooms > 0 && roomType ? false : true
                            }>Book</button>
                    </div>
                </div>
            </div>
        )
    }

    const renderXotel = () => {
        return (
            <div className="card mb-3">
                <div className="card-body">
                    <div className="row">
                        <div className="col-12 col-lg-7">
                            <Splide aria-label="Xotel" options={{
                                type: 'loop',
                                perPage: 1,
                                perMove: 1
                            }}>
                                {XOTEL_IMAGES.map((image, index) => {
                                    return (
                                        <SplideSlide>
                                            <img src={image} alt="" className="img-fluid" />
                                        </SplideSlide>
                                    )
                                })}
                            </Splide>
                        </div>
                        <div className="col-12 col-lg-5">
                            <h5 className="fw-bold">Information</h5>
                            <div className="fw-bold">Xotel: 1,100 THB/room  </div>
                            <div className="small-font mb-3">x 5 Nights = 5,500 THB (Breakfast included)</div>

                            <div><span className="fw-bold mb-3">Standard King Bed :</span> {data?.roomType_1?.available ?? <i class="fa-solid fa-circle-notch fa-spin"></i>} Left</div>
                            <div><span className="fw-bold">Standard Twin Bed :</span> {data?.roomType_2?.available ?? <i class="fa-solid fa-circle-notch fa-spin"></i>} Left</div>
                        </div>
                    </div>

                    <div className="row mt-3">
                        <div className="col-12 col-lg-4 mb-3">
                            <div className="fw-bold mb-2">Choose your room type</div>
                            <div class="card position-relative clickable" id="standard-king-bed" onClick={() => {
                                setRoomType('standard-king-bed');
                                setRoomPrice(5500);
                            }}>
                                <img src='https://pub-3d6ffa377ce448019676a38678d8594b.r2.dev/xotel/xotel-king-bde.jpg' class="card-img-top img-fluid" alt="Xotel Standard King Bed" />
                                <div class="card-body p-2">
                                    <div className="row">
                                        <div className="col-2 d-flex justify-content-center align-items-center">
                                            <div class="form-check">
                                                <input class="form-check-input" type="radio" name="roomType" id="standard-king-bed" value="standard-king-bed" checked={roomType === 'standard-king-bed'} onChange={() => {
                                                    setRoomType('standard-king-bed');
                                                    setRoomPrice(5500);
                                                }} />
                                                <label class="form-check-label" for="standard-king-bed">
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-10">
                                            <div class="card-title fw-bold text-start">Standard King Bed</div>
                                            <div className="small-font">
                                                <ul>
                                                    <li>Breakfast included</li>
                                                    <li>1,100 THB/room x 5 Nights</li>
                                                </ul>
                                            </div>
                                            <div className="text-end fw-bold">5,500 THB</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-4 mb-3">
                            <div className="fw-bold mb-2">‎ </div>
                            <div class="card position-relative clickable" id="standard-twin-bed" onClick={() => {
                                setRoomType('standard-twin-bed');
                                setRoomPrice(5500);
                            }}>
                                <img src='https://pub-3d6ffa377ce448019676a38678d8594b.r2.dev/xotel/9.jpg' class="card-img-top img-fluid" alt="Xotel Standard Twin Bed" />
                                <div class="card-body p-2">
                                    <div className="row">
                                        <div className="col-2 d-flex justify-content-center align-items-center">
                                            <div class="form-check">
                                                <input class="form-check-input" type="radio" name="roomType" id="standard-twin-bed" value="standard-twin-bed" checked={roomType === 'standard-twin-bed'} onChange={() => {
                                                    setRoomType('standard-twin-bed');
                                                    setRoomPrice(5500);
                                                }} />
                                                <label class="form-check-label" for="standard-twin-bed">
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-10">
                                            <div class="card-title fw-bold text-start">Standard Twin Bed</div>
                                            <div className="small-font">
                                                <ul>
                                                    <li>Breakfast included</li>
                                                    <li>1,100 THB/room x 5 Nights</li>
                                                </ul>
                                            </div>
                                            <div className="text-end fw-bold">5,500 THB</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-4 mb-3">
                            <div className="fw-bold mb-2">‎ </div>
                            <div className="mb-4">
                                <div className="fw-bold mb-2 text-center">Number of rooms</div>
                                <div className="d-flex justify-content-center align-items-center">
                                    <i class="fa-solid fa-circle-minus fa-2x me-4 clickable" onClick={() => numberOfRooms > 0 ? setNumberOfRooms(numberOfRooms - 1) : null}></i>
                                    <input type="text" className="form-control text-center w-25" value={numberOfRooms} onChange={(e) => setNumberOfRooms(e.target.value)} readOnly />
                                    <i class="fa-solid fa-circle-plus fa-2x ms-4 clickable" onClick={() => numberOfRooms < 34 ? setNumberOfRooms(numberOfRooms + 1) : null}></i>
                                </div>
                            </div>

                        </div>
                    </div>


                    {renderConfirmation()}

                </div>
            </div>
        )
    }

    const renderBayasita = () => {
        return (
            <div className="card mb-3">
                <div className="card-body">
                    <div className="row">
                        <div className="col-12 col-lg-7">
                            <Splide aria-label="Bayasita" options={{
                                type: 'loop',
                                perPage: 1,
                                perMove: 1
                            }}>
                                {BAYASITA_IMAGES.map((image, index) => {
                                    return (
                                        <SplideSlide>
                                            <img src={image} alt="" className="img-fluid" />
                                        </SplideSlide>
                                    )
                                })}
                            </Splide>
                        </div>
                        <div className="col-12 col-lg-5">
                            <h5 className="fw-bold">Information</h5>
                            <div className="fw-bold">Bayasita : 1,050 THB/room </div>
                            <div className="small-font mb-3">x 5 Nights = 5,250 THB (Breakfast included)</div>

                            <div><span className="fw-bold mb-3">Superior Double :</span> {data?.roomType_1?.available ?? <i class="fa-solid fa-circle-notch fa-spin"></i>} Left</div>
                            <div><span className="fw-bold">Superior Twin :</span> {data?.roomType_2?.available ?? <i class="fa-solid fa-circle-notch fa-spin"></i>} Left</div>
                            <div className="small-font mb-3 mt-3">Extra bed 450 THB/room x 5 Nights = 2,250 THB : {data?.extraBed ?? <i class="fa-solid fa-circle-notch fa-spin"></i>} left</div>
                        </div>
                    </div>

                    <div className="row mt-3">
                        <div className="col-12 col-lg-4 mb-3">
                            <div className="fw-bold mb-2">Choose your room type</div>
                            <div class="card position-relative clickable" id="superior-double" onClick={() => {
                                setRoomType('superior-double')
                                setRoomPrice(5250);
                            }}>
                                <img src='https://pub-3d6ffa377ce448019676a38678d8594b.r2.dev/bayasita/7.jpg' class="card-img-top img-fluid" alt="Bayasita Superior Double" />
                                <div class="card-body p-2">
                                    <div className="row">
                                        <div className="col-2 d-flex justify-content-center align-items-center">
                                            <div class="form-check">
                                                <input class="form-check-input" type="radio" name="roomType" id="superior-double" value="superior-double" checked={roomType === 'superior-double'} onChange={() => {
                                                    setRoomType('superior-double')
                                                    setRoomPrice(5250);
                                                }} />
                                                <label class="form-check-label" for="superior-double">
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-10">
                                            <div class="card-title fw-bold text-start">Superior Double</div>
                                            <div className="small-font">
                                                <ul>
                                                    <li>Breakfast included</li>
                                                    <li>1,050 THB/room x 5 Nights</li>
                                                </ul>
                                            </div>
                                            <div className="text-end fw-bold">5,250 THB</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-4 mb-3">
                            <div className="fw-bold mb-2">‎ </div>
                            <div class="card position-relative clickable" id="superior-twin" onClick={() => {
                                setRoomType('superior-twin')
                                setRoomPrice(5250);
                            }}>
                                <img src='https://pub-3d6ffa377ce448019676a38678d8594b.r2.dev/bayasita/9.jpg' class="card-img-top img-fluid" alt="Bayasita Superior Twin" />
                                <div class="card-body p-2">
                                    <div className="row">
                                        <div className="col-2 d-flex justify-content-center align-items-center">
                                            <div class="form-check">
                                                <input class="form-check-input" type="radio" name="roomType" id="superior-twin" value="superior-twin" checked={roomType === 'superior-twin'} onChange={() => {
                                                    setRoomType('superior-twin')
                                                    setRoomPrice(5250);
                                                }} />
                                                <label class="form-check-label" for="superior-twin">
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-10">
                                            <div class="card-title fw-bold text-start">Superior Twin</div>
                                            <div className="small-font">
                                                <ul>
                                                    <li>Breakfast included</li>
                                                    <li>1,050 THB/room x 5 Nights</li>
                                                </ul>
                                            </div>
                                            <div className="text-end fw-bold">5,250 THB</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-4 mb-3">
                            <div className="fw-bold mb-2">‎ </div>
                            <div className="mb-4">
                                <div className="fw-bold mb-2 text-center">Number of rooms</div>
                                <div className="d-flex justify-content-center align-items-center">
                                    <i class="fa-solid fa-circle-minus fa-2x me-4 clickable" onClick={() => numberOfRooms > 0 ? setNumberOfRooms(numberOfRooms - 1) : null}></i>
                                    <input type="text" className="form-control text-center w-25" value={numberOfRooms} onChange={(e) => setNumberOfRooms(e.target.value)} readOnly />
                                    <i class="fa-solid fa-circle-plus fa-2x ms-4 clickable" onClick={() => numberOfRooms < 65 ? setNumberOfRooms(numberOfRooms + 1) : null}></i>
                                </div>
                            </div>
                            <div className="mb-5">
                                <div className="fw-bold mb-2 text-center">Number of Extra Bed</div>
                                <div className="d-flex justify-content-center align-items-center">
                                    <i class="fa-solid fa-circle-minus fa-2x me-4 clickable" onClick={() => numberOfExtraBeds > 0 ? setNumberOfExtraBeds(numberOfExtraBeds - 1) : null}></i>
                                    <input type="text" className="form-control text-center w-25" value={numberOfExtraBeds} onChange={(e) => setNumberOfExtraBeds(e.target.value)} readOnly />
                                    <i class="fa-solid fa-circle-plus fa-2x ms-4 clickable" onClick={() => numberOfExtraBeds < numberOfRooms ? setNumberOfExtraBeds(numberOfExtraBeds + 1) : null}></i>
                                </div>
                            </div>

                            <div>
                                <span className="small-font text-center">Extra bed 450 THB/room x 5 Nights = 2,250 THB : {data?.extraBed ?? <i class="fa-solid fa-circle-notch fa-spin"></i>} left</span>
                            </div>

                        </div>
                    </div>

                    {renderConfirmation()}

                </div>
            </div>
        )
    }

    const onSelectHotel = (hotel) => {
        setHotel(hotel);
        setRoomType(null);
        setNumberOfRooms(0);
        setNumberOfExtraBeds(0);
        getHotel(hotel);
        setData(null);

        if (hotel === 'bayasita') {
            setExtraBedPrice(450);
            setRoomPrice(5250);
        }

        if (hotel === 'xotel') {
            setExtraBedPrice(0);
            setRoomPrice(5500);
        }
    }

    const book = () => {

        // Confirm booking or not
        Swal.fire({
            title: 'Please read this information first!',
            text: 'Selected options will only be reserved for 10 days. Please complete the transaction to secure your bookings.',
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: 'Confirm',
            cancelButtonText: 'Cancel',
            confirmButtonColor: '#064B72',
            cancelButtonColor: '#ff5f6d',
            reverseButtons: true,
            focusConfirm: false,
            focusCancel: false,
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showLoaderOnConfirm: true,
            preConfirm: () => {
                return new Promise((resolve, reject) => {
                    setTimeout(() => {
                        resolve();
                    }, 2000)
                })
            }
        })
            .then((result) => {
                if (result.isConfirmed) {
                    const book = document.getElementById('book');
                    const originalText = book.innerHTML;
                    document.getElementById('book').innerHTML = '<i class="fa-solid fa-circle-notch fa-spin"></i>';
                    document.getElementById('book').disabled = true;

                    axios.post('/api/book', {
                        hotel: hotel,
                        room_type: roomType,
                        number_of_rooms: numberOfRooms,
                        extra_bed: numberOfExtraBeds,
                        team_id: teamId
                    }).then((res) => {
                        console.log(res.data);
                        Toastify({
                            text: res.data.message,
                            duration: 3000,
                            backgroundColor: "linear-gradient(to right, #00b09b, #96c93d)",
                            className: "info",
                            position: "center",
                            gravity: "bottom",
                            onClick: function () {
                                window.location.href = '/team/manage/' + teamId;
                            },
                            callback: function () {
                                window.location.href = '/team/manage/' + teamId;
                            },
                        }).showToast();
                    }).catch((err) => {
                        console.log(err);
                        Toastify({
                            text: err.response.data?.message ?? 'Something went wrong.',
                            duration: 3000,
                            backgroundColor: "linear-gradient(to right, #ff5f6d, #ffc371)",
                            className: "error",
                            position: "center",
                            gravity: "bottom",
                        }).showToast();
                    }).finally(() => {
                        book.innerHTML = originalText;
                        book.disabled = false;
                    })
                }
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                console.log('Request completed.');
            })
    }

    return (
        <>
            <BackgroundV2 props={{ height: '75vh' }} />
            <NavbarLogo />

            <Helmet>
                <style>{`
                    html, body {
                        padding-top: 75px;
                    }
                `}</style>
            </Helmet>

            <div className="container pb-5">
                <div className="row justify-content-center mb-4 mt-4">
                    <div className="col-12 col-lg-8 mb-4">
                        <h3 className="mb-3 text-center"><span className="label-blue">Accommodations</span></h3>
                    </div>
                </div>

                <div className="row mb-3">
                    <div className="col-12">
                        <div className="text-decoration-underline text-white">Choose your hotel</div>
                    </div>
                </div>

                <div className="row mb-3">
                    <div className="col-12 col-lg-4 mb-3">
                        <div className={`card mb-3 clickable ${hotel === 'bayasita' ? 'selected-hotel' : ''}`} id="bayasita" onClick={() => onSelectHotel('bayasita')}>
                            <div className="card-body p-0">
                                <div className="d-flex justify-content-start align-items-center">
                                    <div>
                                        <img src='https://pub-3d6ffa377ce448019676a38678d8594b.r2.dev/bayasita/6.jpg' alt="" className="img-fluid hotel-image" width={150} />
                                    </div>
                                    <div className="ms-3">
                                        <div className="card-title fw-bold text-start">Bayasita</div>
                                        <div className="small-font">Superior Double : {dataB?.roomType_1?.available ?? <i class="fa-solid fa-circle-notch fa-spin"></i>}</div>
                                        <div className="small-font">Superior Twin : {dataB?.roomType_2?.available ?? <i class="fa-solid fa-circle-notch fa-spin"></i>}</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={`card mb-3 mt-3 clickable ${hotel === 'xotel' ? 'selected-hotel' : ''}`} id="xotel" onClick={() => onSelectHotel('xotel')}>
                            <div className="card-body p-0">
                                <div className="d-flex justify-content-start align-items-center">
                                    <div>
                                        <img src='https://pub-3d6ffa377ce448019676a38678d8594b.r2.dev/xotel/8.jpg' alt="" className="img-fluid hotel-image" width={150} />
                                    </div>
                                    <div className="ms-3">
                                        <div className="card-title fw-bold text-start">Xotel</div>
                                        <div className="small-font">Standard King Bed : {dataX?.roomType_1?.available ?? <i class="fa-solid fa-circle-notch fa-spin"></i>}</div>
                                        <div className="small-font">Standard Twin Bed : {dataX?.roomType_2?.available ?? <i class="fa-solid fa-circle-notch fa-spin"></i>}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-12 col-lg-8 mb-3">
                        {hotel === 'bayasita' ? renderBayasita() : renderXotel()}
                    </div>

                    <div className="col-12 text-center mt-3">
                        <Link to={`/team/manage/${teamId}`} className="text-white">Go to team</Link>
                    </div>
                </div>
            </div>
        </>
    )
}