import Joi from "joi";

export const chooseTripSchema = Joi.object({
    trip: Joi.string().required().messages({
        "string.empty": "Please select a trip.",
        "any.required": "Please select a trip."
    }),
    // required when trip is equal to "Thai aesthetics"
    option: Joi.when("trip", {
        is: "Thai aesthetics",
        then: Joi.string().required().messages({
            "string.empty": "Please select an option.",
            "any.required": "Please select an option."
        })
    }),
    size: Joi.string().required().messages({
        "string.empty": "Please select a size.",
        "any.required": "Please select a size."
    }),
});