import BackgroundV2 from "../../../components/BackgroundV2";
import NavbarLogo from "../../../components/NavbarLogo";
import { Helmet } from "react-helmet";
import QRCode from "react-qr-code";
import axios from "axios";
import { useEffect, useState } from "react";
import Preload from "../../../components/Preload";
import _ from "lodash";
import TeamMemberBox from "../../../components/TeamMemberBox";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import Toastify from "toastify-js";
import html2canvas from 'html2canvas';
import { useParams } from "react-router-dom";

export default function TeamInformation() {

    const [loading, setLoading] = useState(true);
    const [team, setTeam] = useState({});
    const [userInfo, setUserInfo] = useState({});
    const [numberOfObservers, setNumberOfObservers] = useState(0);
    const [numberOfAdvisors, setNumberOfAdvisors] = useState(0);
    const [numberOfMembers, setNumberOfMembers] = useState(0);
    const { teamId } = useParams();
    const [paidAdvisorsCount, setPaidAdvisorsCount] = useState(0);

    const [airline, setAirlines] = useState('');
    const [arrivalDate, setArrivalDate] = useState('');
    const [returnDepartureDate, setReturnDepartureDate] = useState('');

    useEffect(() => {
        Promise.all([getUserInfo(), getTeam()]);
    }, [])

    const getUserInfo = () => {
        const token = localStorage.getItem('access_token');
        axios.get('/api/auth/me', { token }).then((res) => {
            setUserInfo(res.data);
        }).catch((err) => {
            console.log(err);
        }).finally(() => {
            console.log('Request completed.');
        })
    }

    const getTeam = () => {
        const token = localStorage.getItem('access_token');
        axios.get('/api/teams/' + teamId, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((res) => {
            console.log(res.data);
            setTeam(res.data.data);
            setNumberOfObservers(res.data.data.members.filter((member) => member.role === 'observer').length);
            setNumberOfAdvisors(res.data.data.members.filter((member) => member.role === 'advisor').length);
            setNumberOfMembers(res.data.data.members.filter((member) => member.role === 'member').length);
            setPaidAdvisorsCount(res.data.data.members.filter((member) => member.role === 'advisor' && member.is_paid === "Y").length);
            setAirlines(res.data.data?.flight?.airline);
            setArrivalDate(res.data.data?.flight?.arrival_date);
            setReturnDepartureDate(res.data.data?.flight?.return_departure_date);
        }).catch((err) => {
            console.log(err);
        }).finally(() => {
            setLoading(false);
        })
    }

    const removeTeam = (id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Remove'
        }).then((result) => {
            if (result.isConfirmed) {
                axios.delete(`/api/teams/${id}`).then((res) => {
                    Swal.fire(
                        'Deleted!',
                        res.data.message,
                        'success'
                    )
                    window.location.href = '/lobby';
                }).catch((err) => {
                    console.log(err);
                })
            }
        })
    }

    function downloadQr(e, teamId) {
        // convert div to png and download it using html2canvas
        e.target.disabled = true;
        const originalText = e.target.innerHTML;

        e.target.innerHTML = 'Downloading...';

        html2canvas(document.querySelector("#qr-code")).then(canvas => {
            var link = document.createElement('a');
            link.download = `KKUICEM2024#${teamId}.png`;
            link.href = canvas.toDataURL()
            link.click();
        }
        ).finally(() => {
            e.target.disabled = false;
            e.target.innerHTML = originalText;
        })
    }

    const copyToClipboard = (elementId) => {
        const aux = document.createElement("input");
        aux.setAttribute("value", document.getElementById(elementId).innerHTML);
        document.body.appendChild(aux);
        aux.select();
        document.execCommand("copy");
        document.body.removeChild(aux);

        Toastify({
            text: "Copied to clipboard",
            duration: 3000,
            gravity: "bottom",
            position: "center",
            backgroundColor: "#4caf50",
            stopOnFocus: true,
        }).showToast();
    }

    const numberFormat = (value) => {
        const formattedNumber = new Intl.NumberFormat('en-IN').format(value);
        return formattedNumber;
    }

    const wordCapitalize = (str) => {
        return str?.toLowerCase().replace(/\b(\w)/g, s => s.toUpperCase());
    }

    const roomPrice = (hotel) => {
        if (hotel === 'bayasita') {
            return 5250;
        }
        if (hotel === 'xotel') {
            return 5500;
        }
    }

    const cancelBooking = () => {

        const originalText = document.getElementById('cancel').innerHTML;
        document.getElementById('cancel').innerHTML = '<i class="fa-solid fa-circle-notch fa-spin"></i>';

        axios.delete('/api/book/' + teamId).then((res) => {
            Toastify({
                text: "Your booking has been canceled.",
                duration: 3000,
                gravity: "bottom",
                position: "center",
                backgroundColor: "#4caf50",
                stopOnFocus: true,
                onClick: function () {
                    window.location.reload();
                },
                callback: function () {
                    window.location.reload();
                },
            }).showToast();
        }).catch((err) => {
            Toastify({
                text: "Something went wrong.",
                duration: 3000,
                gravity: "bottom",
                position: "center",
                backgroundColor: "#f44336",
                stopOnFocus: true,
                onClick: function () { },
                callback: function () { },
            }).showToast();
        }).finally(() => {
            document.getElementById('cancel').innerHTML = originalText;
        })
    }

    const saveFlight = () => {
        axios.post('/api/teams/flights/' + teamId, {
            airline,
            arrival_date: arrivalDate,
            return_departure_date: returnDepartureDate,
        }).then((res) => {
            Toastify({
                text: "Saved your team flight information.",
                duration: 3000,
                gravity: "bottom",
                position: "center",
                backgroundColor: "#4caf50",
                stopOnFocus: true,
                onClick: function () { },
                callback: function () { },
            }).showToast();
        }).catch((err) => {
            console.log(err);
        }).finally(() => {
            setLoading(false);
        })
    }

    return (
        <>
            <BackgroundV2 props={{ height: '75vh' }} />
            <NavbarLogo />

            <Helmet>
                <style>{`
                    html, body {
                        padding-top: 75px;
                    }
                `}</style>
            </Helmet>

            {!loading ?
                <div className="container pb-5">
                    <div className="row mb-2">
                        <div className="col-12">
                            <h3 className="mb-3"><span className="label-blue">Team</span> <span className="label-red">Information </span></h3>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-lg-6 mb-3">
                            <div className="card modal-custom-bg h-100">
                                <div className="card-body">
                                    <div className="box-lobby card-title text-white"><i class="fa-solid fa-user-plus"></i> Invite Members</div>

                                    <div className="row mt-2">
                                        <div className="col-12 col-lg-4 p-3">
                                            <div id="qr-code" style={{ background: 'white', padding: '16px', width: 'fit-content', margin: 'auto' }} className='text-center'>
                                                <QRCode value={`KKUICEM2024#${team.id}`} size={130} />
                                            </div>
                                            <div className="text-center text-white mt-2" id="joinCode">KKUICEM2024#{team.id}</div>
                                        </div>
                                        <div className="col-12 col-lg-8 text-white">
                                            <ol>How to invite member ?
                                                <li>Download QR code or copy join code.</li>
                                                <li>Share QR code or join code to your friends.</li>
                                                <li>Wait for your friends to join.</li>
                                            </ol>
                                            <div className="row">
                                                <div className="col-6">
                                                    <button className="btn btn-custom-register w-100" onClick={() => copyToClipboard('joinCode')}>Copy join code</button>
                                                </div>
                                                <div className="col-6">
                                                    <button className="btn btn-custom-register w-100" onClick={(e) => downloadQr(e, team.id)}>Download QR code</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-6 mb-3">
                            <div className="card modal-custom-bg h-100">
                                <div className="card-body">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div className="box-lobby card-title text-white"><i class="fa-solid fa-star"></i> Team information</div>
                                        <div className="d-flex">
                                            {userInfo.id === team.leader_id ?
                                                <>
                                                    <div className="box-lobby card-title text-white"><button className="btn btn-sm btn-danger me-2" onClick={() => removeTeam(team.id)}><i class="fa-solid fa-trash-can"></i></button></div>
                                                    <Link to={`/team/edit/${team.id}`} className="text-decoration-none"><div className="box-lobby card-title text-white"><button className="btn btn-sm btn-secondary"><i class="fa-solid fa-pen-to-square"></i></button></div></Link>
                                                </>
                                                : ''}
                                        </div>
                                    </div>

                                    <div className="row mt-2">
                                        <div className="col-4 p-3 text-center">
                                            <img src={team.logo} alt="logo" className="img-fluid" />
                                        </div>
                                        <div className="col-8 text-white">
                                            <div className="mb-0">{team.university_name}</div>
                                            <div className="mb-3"><span className="fw-bolder">University address:</span> {team.university_address}</div>
                                            <div className="mb-3"><span className="fw-bolder">Team name:</span> {team.team_name}</div>
                                            <div className="mb-1"><span className="">Number of advisors:</span> {numberOfAdvisors}</div>
                                            <div className="mb-1"><span className="">Number of observers:</span> {numberOfObservers}</div>
                                            <div className="mb-2"><span className="">Number of members:</span> {numberOfMembers}</div>
                                            <div className="mb-1"><span className="fw-bolder">Total members:</span> {numberOfAdvisors + numberOfObservers + numberOfMembers}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="row">
                        <div className="col-12 col-lg-6 mb-3">
                            <div className="card modal-custom-bg h-100">
                                <div className="card-body">
                                    <div className="box-lobby card-title text-white"><i class="fa-solid fa-star"></i> Team members</div>

                                    <div className="row mt-2 text-white">
                                        {team?.members?.length === 0 ?
                                            <div className="col-12 text-center">
                                                <div className="alert alert-warning" role="alert">
                                                    No members, please invite members.
                                                </div>
                                            </div>
                                            :
                                            <>
                                                <div className="col-12">


                                                    <div className="member-list">

                                                        <TeamMemberBox props={{
                                                            role: 'leader',
                                                            user: {
                                                                first_name: team?.leader?.first_name,
                                                                middle_name: team?.leader?.middle_name,
                                                                last_name: team?.leader?.last_name,
                                                                image_url: team?.leader?.image_url,
                                                            }
                                                        }} />

                                                        {_?.map(team.members, (member) => {
                                                            return (
                                                                <TeamMemberBox props={{ ...member }} />
                                                            )
                                                        }
                                                        )}
                                                    </div>
                                                </div>
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-6 mb-3">
                            <div className="card modal-custom-bg h-100">
                                <div className="card-body d-flex justify-content-center align-items-center flex-column">
                                    <div className="box-lobby card-title text-white"><i class="fa-solid fa-house-user"></i> Accommodations</div>
                                    {/* <div className="row mt-2 text-white p-3 text-center justify-content-center align-items-center h-100">We will update soon.</div> */}


                                    <div className="row w-100">
                                        <div className="col-12">
                                            <div className="card">
                                                <div className="card-body">
                                                    <h5>Registration fees</h5>
                                                    <div className="row mb-3">
                                                        <div className="col-12 fw-bolder">Base price:</div>
                                                        <div className="col-12">Early bird - 15,000/team</div>
                                                        <div className="col-12">Regular - 18,000/team</div>
                                                    </div>
                                                    <div className="row mb-3">
                                                        <div className="col-12 fw-bolder">Advisor & Observer</div>
                                                        <div className="col-12">Early bird - 4,000/person</div>
                                                        <div className="col-12">Regular - 5,000/person</div>
                                                    </div>
                                                    {team.bookings?.length > 0 &&
                                                    <div className="row">
                                                        <div className="col-12">Base fees : <span className="text-danger fw-bold">15,000</span> THB</div>
                                                        <div className="col-12">Advisor Fee: <span className="text-danger fw-bold">{numberOfAdvisors}</span> x {numberFormat(team.bookings[0].advisor_and_observer_fee)} = <span className="text-danger fw-bold">{numberFormat(team.bookings[0].advisor_and_observer_fee * numberOfAdvisors)}</span> THB</div>
                                                        <div className="col-12">Observer Fee: <span className="text-danger fw-bold">{numberOfObservers}</span> x {numberFormat(team.bookings[0].advisor_and_observer_fee)} = <span className="text-danger fw-bold">{numberFormat(team.bookings[0].advisor_and_observer_fee * numberOfObservers)}</span> THB</div>
                                                        <div className="col-12">Paid advisor: <span className="text-danger fw-bold">{paidAdvisorsCount}</span> x {numberFormat(team.bookings[0].advisor_and_observer_fee)} = <span className="text-success fw-bold"> -{numberFormat(team.bookings[0].advisor_and_observer_fee * paidAdvisorsCount)}</span> THB</div>
                                                        <div className="col-12">Registration fees : <span className="fw-bold text-danger">{numberFormat(Number(team.bookings[0].registration_fee) + (team.bookings[0].advisor_and_observer_fee * numberOfAdvisors) + (team.bookings[0].advisor_and_observer_fee * numberOfObservers) - (team.bookings[0].advisor_and_observer_fee * paidAdvisorsCount))}</span> THB</div>
                                                    </div>
                                                    }
                                                </div>
                                            </div>
                                            {team.bookings?.length > 0 &&
                                                <div className="card mt-3">
                                                    <div className="card-body">
                                                        <h5>Accommodations</h5>
                                                        <div>Hotel name : {wordCapitalize(team.bookings[0].hotel_name)}</div>
                                                        <div>Length of stay : 5 days</div>
                                                        <div>Room type : {wordCapitalize(team.bookings[0].room_type.replace('-', ' '))} = {numberFormat(roomPrice(team.bookings[0].hotel_name))}/room</div>
                                                        <div>Number of room : <span className="fw-bold text-danger">{team.bookings[0].room_count}</span> rooms x {numberFormat(roomPrice(team.bookings[0].hotel_name))} = <span className="fw-bold text-danger">{numberFormat(roomPrice(team.bookings[0].hotel_name) * team.bookings[0].room_count)}</span> THB</div>
                                                        {team.bookings[0].extra_bed > 0 &&
                                                            <div>Number of extra bed : <span className="fw-bold text-danger">{team.bookings[0].extra_bed}</span> extra beds x {numberFormat(450 * 5)} THB = <span className="fw-bold text-danger">{numberFormat((450 * 5) * Number(team.bookings[0].extra_bed))}</span> THB</div>
                                                        }
                                                        {team.bookings?.length > 0 &&
                                                            <div className="row">
                                                                <div className="col-12">Accommodation fees : <span className="fw-bold text-danger">{numberFormat(team.bookings[0].booking_price)}</span> THB</div>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            }

                                             {team.bookings?.length > 0 &&
                                                <div className="card mt-3">
                                                    <div className="card-body">
                                                        <div>Total price: <span className="fw-bold text-success">{numberFormat(Number(team.bookings[0].booking_price) + Number(team.bookings[0].registration_fee) + (team.bookings[0].advisor_and_observer_fee * numberOfAdvisors) + (team.bookings[0].advisor_and_observer_fee * numberOfObservers) - (team.bookings[0].advisor_and_observer_fee * paidAdvisorsCount))}</span> THB</div>
                                                    </div>
                                                </div>
                                            }
                                            <div className="alert alert-secondary mt-3 text-center w-100 mb-2" role="alert">
                                                {team.bookings?.length > 0 ?
                                                    <>
                                                        <div><i className="fa-solid fa-circle-check fa-1x text-success"></i> You have booked accommodations.</div>
                                                        <div>If you want to change or update your accommodations, <span className="fw-bold text-danger text-decoration-underline" id="cancel" onClick={cancelBooking} style={{ cursor: 'pointer' }}>Cancel</span>.</div>
                                                    </>
                                                    :
                                                    <div><i className="fa-solid fa-circle-info fa-1x"></i> Please choose a accommodations.</div>
                                                }
                                            </div>
                                            {team.bookings?.length <= 0 &&
                                                <Link to={`/accommodations/${teamId}`} className="text-decoration-none">
                                                    <button className="btn yellow-neon-button w-100">Choose</button>
                                                </Link>
                                            }
                                        </div>
                                    </div>

                                    {team.bookings?.length > 0 &&
                                        <div className="row w-100">
                                            <div className="col-12">
                                                <div className="alert alert-secondary mt-3 text-center w-100 mb-2" role="alert">
                                                    {team.transactions ?
                                                        <div><i className="fa-solid fa-circle-check fa-1x text-success"></i> You have confirmed payment.</div>
                                                        :
                                                        <div><i className="fa-solid fa-circle-info fa-1x"></i> Please make a payment to confirm your accommodations.</div>
                                                    }
                                                </div>
                                                <Link to={`/payment/${teamId}`} className="text-decoration-none">
                                                    {team.transactions ?
                                                        <button className="btn btn-secondary w-100">Check payment</button>
                                                        :
                                                        <button className="btn yellow-neon-button w-100">Make a payment</button>
                                                    }
                                                </Link>
                                            </div>
                                        </div>
                                    }

                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12 col-lg-6 mb-3">
                            <div className="card modal-custom-bg h-100">
                                <div className="card-body">
                                    <div className="box-lobby card-title text-white"><i className="fa-solid fa-location-dot"></i> Flight Information</div>
                                    <div className="form-group mt-1 mb-2">
                                        <label className="text-white mb-1">Airlines</label>
                                        <input type="text" className="form-control" value={airline} onInput={(e) => setAirlines(e.target.value)} />
                                    </div>
                                    <div className="form-group mt-1 mb-2">
                                        <label className="text-white mb-1">Arrival at</label>
                                        <input type="datetime-local" className="form-control" value={arrivalDate} onInput={(e) => setArrivalDate(e.target.value)} />
                                    </div>
                                    <div className="form-group mt-1 mb-2">
                                        <label className="text-white mb-1">Return departure at</label>
                                        <input type="datetime-local" className="form-control" value={returnDepartureDate} onInput={(e) => setReturnDepartureDate(e.target.value)} />
                                    </div>
                                    <button className="btn btn-custom-register w-100" onClick={saveFlight}>Save</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row mb-4 mt-3">
                        <div className="col-12 text-center mt-3">
                            <Link to="/lobby" className="text-white">Go to lobby</Link>
                        </div>
                    </div>
                </div>
                :
                <Preload />
            }
        </>
    )
}