import Joi from "joi";
import { tlds } from '@hapi/tlds';

export const registerSchema = Joi.object({
    first_name: Joi.string().max(64).required().messages({   
        'string.empty': `First name cannot be empty.`,
        'string.max': `First name cannot be longer than {#limit} characters.`,
        'any.required': `First name is required.`
    }),
    last_name: Joi.string().max(64).required().messages({
        'string.empty': `Last name cannot be empty.`,
        'string.max': `Last name cannot be longer than {#limit} characters.`,
        'any.required': `Last name is required.`
    }),
    email: Joi.string().email({ tlds: { allow: tlds } }).required().messages({
        'string.empty': `Email cannot be empty.`,
        'string.email': `Email must be a valid email.`,
        'any.required': `Email is required.`
    }),
    password: Joi.string().min(8).max(64).required().messages({
        'string.empty': `Password cannot be empty.`,
        'string.min': `Password must be at least {#limit} characters.`,
        'string.max': `Password cannot be longer than {#limit} characters.`,
        'any.required': `Password is required.`
    }),
    confirm_password: Joi.string().valid(Joi.ref('password')).required().messages({
        'string.empty': `Confirm password cannot be empty.`,
        'any.only': `Confirm password must match password.`,
        'any.required': `Confirm password is required.`
    })
});
