import { Splide, SplideSlide } from '@splidejs/react-splide';

import agenda1 from '../assets/images/agenda/agenda-1.png';
import agenda2 from '../assets/images/agenda/agenda-2.png';
import agenda3 from '../assets/images/agenda/agenda-3.png';
import agenda4 from '../assets/images/agenda/agenda-4.png';
import agenda5 from '../assets/images/agenda/agenda-5.png';

import agenda from '../assets/docs/9th-KKU-ICEM-Agenda.pdf';

export default function Agenda() {

    const downloadAgenda = () => {
        window.open(agenda, '_blank');
    }

    return (
        <div className="container-fluid mt-5 mb-5">
            <div className="row">
                <div className="col-12 pe-lg-5 ps-lg-5">
                    <div className="timeline">
                        <div className="text-center">
                            <h1 className="label-red text-divider">Agenda</h1>


                            <Splide aria-label="Agenda" options={{
                                type: 'loop',
                            }}>
                                <SplideSlide>
                                    <img src={agenda1} alt="Day 1" className='img-fluid' />
                                </SplideSlide>
                                <SplideSlide>
                                    <img src={agenda2} alt="Day 2" className='img-fluid' />
                                </SplideSlide>
                                <SplideSlide>
                                    <img src={agenda3} alt="Day 3" className='img-fluid' />
                                </SplideSlide>
                                <SplideSlide>
                                    <img src={agenda4} alt="Day 4" className='img-fluid' />
                                </SplideSlide>
                                <SplideSlide>
                                    <img src={agenda5} alt="Day 5" className='img-fluid' />
                                </SplideSlide>
                            </Splide>


                        </div>
                    </div>
                </div>

                <div className='row mt-3'>
                    <div className='col-12'>
                        <div className="custom-button btn-custom-register p-3" onClick={downloadAgenda}>Download Full Agenda</div>
                    </div>
                </div>
            </div>
        </div>
    )
}