import { useState } from "react";
import Background from "../components/Background";
import NavbarLogo from "../components/NavbarLogo";
import { Link } from "react-router-dom";
import { registerSchema } from "../schema/register.schema";
import axios from "axios";
import _ from "lodash";
import Padding from "../components/Padding";
import Toastify from 'toastify-js'
import RegisterTerm from "../components/RegisterTerm";
import { Helmet } from "react-helmet";

export default function Register() {

    const [isAccept, setIsAccept] = useState(false);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const onAccept = (e) => {
        setIsAccept(e.target.checked);
        if (e.target.checked) {
            document.querySelector('.disabled-register-btn').classList.remove('disabled-register-btn');
        } else {
            document.querySelector('.btn-custom-register').classList.add('disabled-register-btn');
        }
    }

    const confirm = () => {
        const payload = {
            first_name: firstName,
            last_name: lastName,
            email: email,
            password: password,
            confirm_password: confirmPassword
        }
        const validate = registerSchema.validate(payload, { abortEarly: false });

        if (validate?.error) {
            validate.error.details.forEach((err) => {
                document.getElementById(err.context.key).classList.add('is-invalid');
                document.getElementById(err.context.key + 'Feedback').innerHTML = err.message;
            })
            return;
        }

        const originalText = document.getElementById('regist').innerHTML;
        document.getElementById('regist').innerHTML = '<i class="fa-solid fa-circle-notch fa-spin text-white"></i>';


        axios.post('/api/auth/register', payload).then((res) => {
            if (res.status === 201) {
                return Toastify({
                    text: 'Register successfully!, redirecting to login page...',
                    duration: 3000,
                    gravity: 'bottom',
                    position: 'center',
                    backgroundColor: '#82CD47',
                    stopOnFocus: true,
                    onClick: function () {
                        window.location.href = '/login';
                    },
                    callback: function () {
                        window.location.href = '/login';
                    }
                }).showToast();
            }
        }).catch((err) => {
            if (err.response) {
                const errors = err.response.data.errors;
                if (errors) {
                    Object.keys(errors).forEach((key) => {
                        document.getElementById(key).classList.add('is-invalid');
                        document.getElementById(key + 'Feedback').innerHTML = errors[key][0];
                    })
                }
            }
        }).finally(() => {
            console.log('Request completed.');
            document.getElementById('regist').innerHTML = originalText;
        })
    }

    const onInputChange = (e) => {
        const input = e.target;
        const inputId = input.id;
        const inputValue = input.value;

        if (inputId === 'accept') {
            if (input.checked) {
                input.classList.remove('is-invalid');
                document.getElementById(inputId + 'Feedback').innerHTML = '';
            } else {
                input.classList.add('is-invalid');
                document.getElementById(inputId + 'Feedback').innerHTML = 'This field is required.';
            }
            return;
        }

        if (_.isEmpty(inputValue)) {
            input.classList.add('is-invalid');
            document.getElementById(inputId + 'Feedback').innerHTML = 'This field is required.';
        } else {
            input.classList.remove('is-invalid');
            document.getElementById(inputId + 'Feedback').innerHTML = '';
        }

        switch (inputId) {
            case 'first_name':
                setFirstName(inputValue);
                break;
            case 'last_name':
                setLastName(inputValue);
                break;
            case 'email':
                setEmail(inputValue);
                break;
            case 'password':
                setPassword(inputValue);
                break;
            case 'confirm_password':
                setConfirmPassword(inputValue);
                break;
            default:
                break;
        }
    }

    return (
        <>
            <Padding />
            <Background />
            <NavbarLogo />
            <Helmet>
                <style>{`
                    html, body {
                        padding-top: 45px;
                    }
                `}</style>
            </Helmet>
            <div className="container h-100">
                <div className="row h-100 align-items-center">
                    <div className="col-12 col-lg-7 d-none d-lg-block text-white pb-5">
                        <RegisterTerm />
                    </div>
                    <div className="col-12 col-lg-5">
                        <h1 className="mb-5 text-center"><span className="label-blue">WELCOME TO</span> <span className="label-red">KKU ICEM!</span></h1>
                        <div className="login-label mb-3 pe-3 ps-3 text-center">Register</div>

                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-12 col-md-8">
                                    <div className="row mb-3">
                                        <div className="col-6">
                                            <div className="form-group">
                                                <input type="text" className="form-control" placeholder="First name" id="first_name" onChange={onInputChange} />
                                                <div className="invalid-feedback" id="first_nameFeedback"></div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="form-group">
                                                <input type="text" className="form-control" placeholder="Last name" id="last_name" onChange={onInputChange} />
                                                <div className="invalid-feedback" id="last_nameFeedback"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group mb-3">
                                        <input type="email" className="form-control" placeholder="Email address" id="email" onChange={onInputChange} />
                                        <div className="invalid-feedback" id="emailFeedback"></div>
                                    </div>
                                    <div className="form-group mb-3">
                                        <input type="password" className="form-control" placeholder="Password" id="password" onChange={onInputChange} />
                                        <div className="invalid-feedback" id="passwordFeedback"></div>
                                    </div>
                                    <div className="form-group mb-3">
                                        <input type="password" className="form-control" placeholder="Confirm password" id="confirm_password" onChange={onInputChange} />
                                        <div className="invalid-feedback" id="confirm_passwordFeedback"></div>
                                    </div>

                                    <div className="row mb-3">
                                        <div className="col-12">
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" value="" onChange={(event) => {
                                                    onAccept(event);
                                                    onInputChange(event);
                                                }} id="accept" />
                                                <label className="form-check-label text-white" htmlFor="accept">
                                                    I agree with <Link to="#" className="text-decoration text-white" data-bs-toggle="modal" data-bs-target="#termsAndConditionsModal">terms and conditions</Link>
                                                </label>
                                                <div className="invalid-feedback" id="acceptFeedback"></div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="text-white mb-4">Already have an account? <Link to="/login" className="text-decoration text-white">Login</Link></div>

                                    <div className="d-flex justify-content-center">
                                        <div className="custom-button btn-custom-register disabled-register-btn" id="regist" onClick={() => confirm()}>Register !</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* modal */}
            <div className="modal fade" id="termsAndConditionsModal" tabIndex="-1" aria-labelledby="termsAndConditionsModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
                    <div className="modal-content">
                        <div className="modal-body modal-custom-bg">
                            <h1 className="mb-5 text-center"><span className="label-blue">Terms and</span> <span className="label-red">Conditions</span></h1>
                            <RegisterTerm />
                        </div>
                        <div className="modal-footer modal-custom-bg justify-content-center">
                            <button type="button" className="btn btn-custom-register" data-bs-dismiss="modal">Accept</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}