export default function RegisterTerm() {
    return (
        <div className="container">
            <div className="row justify-content-center text-white">
                <div className="col-12">
                    <h1 className="label-red">To register</h1>
                    <div>To register,</div>
                    <div>- Create an individual account for each member</div>
                    <div>- Join as a team via a QR code generated by the team leader</div>
                    <div>- Login and fill out the information</div>
                    <div>- Required documents as listed below:</div>
                    <div>- Passport information</div>
                    <div>- Proof of enrollment</div>
                    <div>- Transaction document</div>
                    <div>- Registration fees</div>
                    <div>- Team (consisting of 3 members)</div>
                    <div style={{
                        whiteSpaceCollapse: 'break-spaces'
                    }}>     Early bird registration 15,000 THB/team</div>
                    <div style={{
                        whiteSpaceCollapse: 'break-spaces'
                    }}>     Regular registration 18,000 THB/team</div>
                    <div>- Advisor (required) and observer</div>
                    <div style={{
                        whiteSpaceCollapse: 'break-spaces'
                    }}>     Early bird registration 4,000 THB/person</div>
                    <div style={{
                        whiteSpaceCollapse: 'break-spaces'
                    }}>     Regular registration 5,000 THB/person</div>
                    <div>(with special program including trips and food)</div>
                    <div>- The registration fees listed above DO NOT include any accommodation</div>
                    <div>- You can choose your own preferred hotel rooms and pay accordingly via our website</div>
                    <div>- Payment beyond early-bird registration (December 5th 2023; 11.59 PM GMT +7.00) will be charged as a regular registration fee regardless of the prior date of application</div>
                    <div>- Personal information and documents can be edited throughout the registration period</div>
                    <div>- We will contact you through the email registered on the team leader's account</div>
                    <div className="text-center fw-bolder text-danger mt-2 mb-2">End of registration date: December 31st, 2023; 11.59 PM GMT +7.00</div>
                    <h5 className="text-center">Should you have any question, do not hesitate to contact us via</h5>
                    <h5 className="text-center">kkuicem.official@gmail.com</h5>
                </div>
            </div>
        </div>
    )
}