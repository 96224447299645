import Joi from "joi";

export const createTeamSchema = Joi.object({
    image: Joi.object().required().allow(null, '').messages({
        'string.empty': `Image cannot be empty.`,
        'any.required': `Image is required.`
    }),
    university_name: Joi.string().required().messages({
        'string.empty': `University name cannot be empty.`,
        'any.required': `University name is required.`
    }),
    university_address: Joi.string().required().messages({
        'string.empty': `University address cannot be empty.`,
        'any.required': `University address is required.`
    }),
    team_name: Joi.string().required().messages({
        'string.empty': `Team name cannot be empty.`,
        'any.required': `Team name is required.`
    }),
    // number_of_observers: Joi.string().required().messages({
    //     'string.empty': `Number of observers cannot be empty.`,
    //     'any.required': `Number of observers is required.`
    // })
});