import { Link } from "react-router-dom";

export default function LogoutButton() {

    const logout = () => {
        localStorage.removeItem('access_token');
        window.location.href = '/';
    }


    return (
        <Link to="#" className="text-decoration-none" onClick={logout}>
            <div className="custom-button btn-custom-register">
                <i className="fa-solid fa-power-off fa-1x"></i> Logout
            </div>
        </Link>
    )
}