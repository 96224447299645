import { Helmet } from "react-helmet";
import BackgroundV2 from "../components/BackgroundV2";
import LeftComponetText from "../components/LeftComponetText";
import Navbar from "../components/Navbar";
import RightComponetText from "../components/RightComponetText";
import RegisterButton from "../components/RegisterButton";
import arrow from '../assets/images/arrow.png';

import section1 from '../assets/images/non-academic/section1.png';
import section2 from '../assets/images/non-academic/section2.png';
import section3 from '../assets/images/non-academic/section3.png';
import section4 from '../assets/images/non-academic/section4.png';
import section5 from '../assets/images/non-academic/section5.png';
import section6 from '../assets/images/non-academic/section6.png';
import section7 from '../assets/images/non-academic/section7.png';

import { Link } from "react-router-dom";
import Footer from "../components/Footer";
import Gallery from "../components/Gallery";

export default function NonAcademic() {
    const renderContentSection1 = () => {
        return (
            <div className="ps-lg-5 d-flex flex-column justify-content-center h-100">
                <h1 className="mb-3 text-center text-lg-start"><span className="label-blue">NON - ACADEMIC</span></h1>
                <h1 className="mb-3 text-center text-lg-start"><span className="label-red"> ACTIVITIES</span></h1>
                <div className="description text-center text-lg-start">Take a step back from your white gown. Here, we’re heading to destinations full of fresh activities and cultural beauty. Laugh with us and let’s enjoy the best of both worlds.</div>
                <div className="w-100 d-flex justify-content-center justify-content-lg-start mt-4">
                    <RegisterButton />
                </div>
            </div>
        )
    }

    const renderImageSection1 = () => {
        return (
            <div className="text-center text-lg-end">
                <img src={section1} alt="section1" className="img-fluid" />
            </div>
        )
    }

    const renderContentSection2 = () => {
        return (
            <div className="pe-lg-5 d-flex flex-column justify-content-center h-100">
                <h1 className="text-center text-lg-start"><span className="label-blue">Thai</span></h1>
                <h1 className="mb-3 text-center text-lg-start"><span className="label-red">Aesthetics</span></h1>
                <div className="description text-center text-lg-start">Stands in the heart of Khonkaen, a “9 storied Nongwaeng Pagoda” provides you with a panoramic landscape of Khonkaen Downtown and a cool breeze from a nearby Kaen nakorn lake accompanied with a relaxing authentic thai massage to calm your minds and bodies</div>
            </div>
        )
    }

    const renderImageSection2 = () => {
        return (
            <div className="text-center text-lg-start">
                <img src={section2} alt="section1" className="img-fluid" />
            </div>
        )
    }

    const renderContentSection3 = () => {
        return (
            <div className="ps-lg-5 d-flex flex-column justify-content-center h-100">
                <h1 className="text-center text-lg-start"><span className="label-blue">Columbo</span></h1>
                <h1 className="mb-3 text-center text-lg-start"><span className="label-red">craft village</span></h1>
                <div className="description text-center text-lg-start">Let yourselves dive in your imagination. Enhance your skill and craftsmanship with various DIY stuffs at Columbo craft village</div>
            </div>
        )
    }

    const renderImageSection3 = () => {
        return (
            <div className="text-center text-lg-start">
                <img src={section3} alt="section1" className="img-fluid" />
            </div>
        )
    }

    const renderContentSection4 = () => {
        return (
            <div className="pe-lg-5 d-flex flex-column justify-content-center h-100">
                <h1 className="text-center text-lg-start"><span className="label-blue">Mekin</span> <span className="label-red"> farm</span></h1>
                <div className="description text-center text-lg-start">Time to escape from a bustling life of the city! Experience Thai-rural lifestyle and explore organic food fresh from farm at Mekin farm.</div>
            </div>
        )
    }

    const renderImageSection4 = () => {
        return (
            <div className="text-center text-lg-start">
                <img src={section4} alt="section1" className="img-fluid" />
            </div>
        )
    }

    const renderContentSection5 = () => {
        return (
            <div className="ps-lg-5 d-flex flex-column justify-content-center h-100">
                <h1 className="text-center text-lg-start"><span className="label-blue">Arcade</span> <span className="label-red">Game night</span></h1>
                <div className="description text-center text-lg-start">Bring you back to those nostalgic experiences from your favorite video games. Enjoy playing arcade games-themed activities with a little surprize from our ACAD team</div>
            </div>
        )
    }

    const renderImageSection5 = () => {
        return (
            <div className="text-center text-lg-start">
                <img src={section5} alt="section1" className="img-fluid" />
            </div>
        )
    }

    const renderContentSection6 = () => {
        return (
            <div className="pe-lg-5 d-flex flex-column justify-content-center h-100">
                <h1 className="text-center text-lg-start"><span className="label-blue">Khon kaen</span></h1>
                <h1 className="mb-3 text-center text-lg-start"><span className="label-red">night market</span></h1>
                <div className="description text-center text-lg-start">Have you ever wondered how local people live their lives? Experience Khon Kaen as a local by walking along the streets full of foods and drinks accompanied with live music to calm your souls at Ton Tan Night Market</div>
            </div>
        )
    }

    const renderImageSection6 = () => {
        return (
            <div className="text-center text-lg-start">
                <img src={section6} alt="section1" className="img-fluid" />
            </div>
        )
    }

    const renderContentSection7 = () => {
        return (
            <div className="ps-lg-5 d-flex flex-column justify-content-center h-100">
                <h1 className="text-center text-lg-start"><span className="label-blue">Farewell</span> <span className="label-red">party</span></h1>
                <div className="description text-center text-lg-start">Let’s appreciate this precious moment together for the last time, until we meet again:)</div>
            </div>
        )
    }

    const renderImageSection7 = () => {
        return (
            <div className="text-center text-lg-start">
                <img src={section7} alt="section1" className="img-fluid" />
            </div>
        )
    }

    return (
        <>
            <BackgroundV2 />
            <Navbar />
            <Helmet>
                <style>{`
                      html, body {
                          padding-top: 60px;
                      }
                  `}</style>
            </Helmet>
            <div className="container-fluid">
                <LeftComponetText props={{ content: renderContentSection1(), image: renderImageSection1() }} />

                <div className="row justify-content-center mb-4 mt-4">
                    <div className="col-6 text-center col-lg-2">
                        <img src={arrow} alt="arrow" className="img-fluid" />
                    </div>
                </div>

                <RightComponetText props={{ content: renderContentSection2(), image: renderImageSection2() }} />
                <LeftComponetText props={{ content: renderContentSection3(), image: renderImageSection3() }} />
                <RightComponetText props={{ content: renderContentSection4(), image: renderImageSection4() }} />
                <LeftComponetText props={{ content: renderContentSection5(), image: renderImageSection5() }} />
                <RightComponetText props={{ content: renderContentSection6(), image: renderImageSection6() }} />
                <LeftComponetText props={{ content: renderContentSection7(), image: renderImageSection7() }} />

                <div className="row mb-5 justify-content-center">
                    <div className="col-11 pe-lg-5 ps-lg-5 text-center">
                        <Link to="/register" className="text-decoration-none ps-lg-5 pe-lg-5">
                            <div className="custom-button btn-custom-register p-3">Register NOW !</div>
                        </Link>
                    </div>
                </div>
            </div>

            <Gallery />
                      
            <Footer />
        </>
    )
}