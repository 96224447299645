import { tlds } from "@hapi/tlds";
import Joi from "joi";

export const forgotPasswordSchema = Joi.object({
    email: Joi.string().email({ tlds: { allow: tlds }}).required().messages({
        'string.empty': `Email cannot be empty.`,
        'string.email': `Email must be a valid email.`,
        'any.required': `Email is required.`
    })
});
