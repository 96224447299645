import BackgroundV2 from "../components/BackgroundV2";
import Navbar from "../components/Navbar";

import section1 from '../assets/images/academic/section1.png';
import section2 from '../assets/images/academic/section2.png';
import section3 from '../assets/images/academic/section3.png';
import section4 from '../assets/images/academic/section4.png';
import section5 from '../assets/images/academic/section5.png';
import section6 from '../assets/images/academic/section6.png';
import section7 from '../assets/images/academic/section7.png';
import section8 from '../assets/images/academic/section8.png';

import RegisterButton from "../components/RegisterButton";
import { Helmet } from "react-helmet";
import LeftComponetText from "../components/LeftComponetText";
import RightComponetText from "../components/RightComponetText";
import arrow from '../assets/images/arrow.png';
import { Link } from "react-router-dom";
import Footer from "../components/Footer";
import Gallery from "../components/Gallery";

export default function Academic() {
    const renderContentSection1 = () => {
        return (
            <div className="ps-lg-5 d-flex flex-column justify-content-center h-100">
                <h1 className="mb-3 text-center text-lg-start"><span className="label-blue">ACADEMIC</span><span className="label-red"> ACTIVITIES</span></h1>
                <div className="description text-center text-lg-start">Including: competition rounds (MCQ, MEQ, SAQ, Semi-final round and Trauma Emergency Simulation) and emergency workshops</div>
                <div className="w-100 d-flex justify-content-center justify-content-lg-start mt-4">
                    <RegisterButton />
                </div>
            </div>
        )
    }

    const renderImageSection1 = () => {
        return (
            <div className="text-center text-lg-end">
                <img src={section1} alt="section1" className="img-fluid" />
            </div>
        )
    }

    const renderContentSection2 = () => {
        return (
            <div className="pe-lg-5 d-flex flex-column justify-content-center h-100">
                <h1 className="mb-3 text-center text-lg-start"><span className="label-blue">MCQ</span></h1>
                <h1 className="mb-3 text-center text-lg-start"><span className="label-red">(Multiple-Choice Question)</span></h1>
                <div className="description text-center text-lg-start">The Multiple-Choice Question (MCQ) round consists of 120 questions. Participants will take the exam individually and choose the one best answer for each question.</div>
            </div>
        )
    }

    const renderImageSection2 = () => {
        return (
            <div className="text-center text-lg-start">
                <img src={section2} alt="section1" className="img-fluid" />
            </div>
        )
    }

    const renderContentSection3 = () => {
        return (
            <div className="ps-lg-5 d-flex flex-column justify-content-center h-100">
                <h1 className="text-center text-lg-start"><span className="label-blue">MEQ</span></h1>
                <h1 className="mb-3 text-center text-lg-start"><span className="label-red">(Multiple Essay Question)</span></h1>
                <div className="description text-center text-lg-start">The Multiple Essay Question (MEQ) round is a written test which consists of 1 case with 4 continuous scenarios.  Each scenario must be done in an orderly manner.</div>
            </div>
        )
    }

    const renderImageSection3 = () => {
        return (
            <div className="text-center text-lg-start">
                <img src={section3} alt="section1" className="img-fluid" />
            </div>
        )
    }

    const renderContentSection4 = () => {
        return (
            <div className="pe-lg-5 d-flex flex-column justify-content-center h-100">
                <h1 className="text-center text-lg-start"><span className="label-blue">SAQ</span></h1>
                <h1 className="mb-3 text-center text-lg-start"><span className="label-red">(Short-Answer Question)</span></h1>
                <div className="description text-center text-lg-start">The Short-Answer Question (SAQ) round will be held as 20 rotating stations consisting of 17 questions and 3 breaks. 90 seconds will be given for each station.</div>
            </div>
        )
    }

    const renderImageSection4 = () => {
        return (
            <div className="text-center text-lg-start">
                <img src={section4} alt="section1" className="img-fluid" />
            </div>
        )
    }

    const renderContentSection5 = () => {
        return (
            <div className="ps-lg-5 d-flex flex-column justify-content-center h-100">
                <h1 className="text-center text-lg-start"><span className="label-blue">Semi-final</span> <span className="label-red">Round</span></h1>
                <div className="description text-center text-lg-start">8 teams with the highest team scores from preliminary round will be competing in this Semi-final round which is a team-based and computer-based competition.</div>
            </div>
        )
    }

    const renderImageSection5 = () => {
        return (
            <div className="text-center text-lg-start">
                <img src={section5} alt="section1" className="img-fluid" />
            </div>
        )
    }

    const renderContentSection6 = () => {
        return (
            <div className="pe-lg-5 d-flex flex-column justify-content-center h-100">
                <h1 className="text-center text-lg-start"><span className="label-blue">Emergency</span></h1>
                <h1 className="mb-3 text-center text-lg-start"><span className="label-red">Simulation Round</span></h1>
                <div className="description text-center text-lg-start">The emergency simulation round is a team-based competition between the 4 teams who have advanced from Semi-final round. Challenging tasks await to be completed!</div>
            </div>
        )
    }

    const renderImageSection6 = () => {
        return (
            <div className="text-center text-lg-start">
                <img src={section6} alt="section1" className="img-fluid" />
            </div>
        )
    }

    const renderContentSection7 = () => {
        return (
            <div className="ps-lg-5 d-flex flex-column justify-content-center h-100">
                <h1 className="text-center text-lg-start"><span className="label-blue">Academic</span> <span className="label-red">Workshop</span></h1>
                <div className="description text-center text-lg-start">General emergency workshop</div>
                <ol className="description text-center text-lg-start">
                    <li>ETT intubation/ airway management</li>
                    <li>ACLS</li>
                    <li>Nasal packing</li>
                    <li>Plain radiographs interpretation</li>
                </ol>
            </div>
        )
    }

    const renderImageSection7 = () => {
        return (
            <div className="text-center text-lg-start">
                <img src={section7} alt="section1" className="img-fluid" />
            </div>
        )
    }

    const renderContentSection8 = () => {
        return (
            <div className="pe-lg-5 d-flex flex-column justify-content-center h-100">
                <h1 className="text-center text-lg-start"><span className="label-blue">Trauma</span></h1>
                <h1 className="mb-3 text-center text-lg-start"><span className="label-red">emergency workshop</span></h1>
                <ol className="description text-center text-lg-start">
                    <li>eFAST</li>
                    <li>Patient packaging</li>
                    <li>Wound suturing & Tourniquet</li>
                    <li>ICD & Needle thoracocentesis</li>
                </ol>
            </div>
        )
    }

    const renderImageSection8 = () => {
        return (
            <div className="text-center text-lg-start">
                <img src={section8} alt="section1" className="img-fluid" />
            </div>
        )
    }

    return (
        <>
            <BackgroundV2 />
            <Navbar />
            <Helmet>
                <style>{`
                      html, body {
                          padding-top: 60px;
                      }
                  `}</style>
            </Helmet>
            <div className="container-fluid">
                <LeftComponetText props={{ content: renderContentSection1(), image: renderImageSection1() }} />

                <div className="row justify-content-center mb-4 mt-4">
                    <div className="col-6 text-center col-lg-2">
                        <img src={arrow} alt="arrow" className="img-fluid" />
                    </div>
                </div>

                <RightComponetText props={{ content: renderContentSection2(), image: renderImageSection2() }} />
                <LeftComponetText props={{ content: renderContentSection3(), image: renderImageSection3() }} />
                <RightComponetText props={{ content: renderContentSection4(), image: renderImageSection4() }} />
                <LeftComponetText props={{ content: renderContentSection5(), image: renderImageSection5() }} />
                <RightComponetText props={{ content: renderContentSection6(), image: renderImageSection6() }} />
                <LeftComponetText props={{ content: renderContentSection7(), image: renderImageSection7() }} />
                <RightComponetText props={{ content: renderContentSection8(), image: renderImageSection8() }} />

                <div className="row mb-5 justify-content-center">
                    <div className="col-11 pe-lg-5 ps-lg-5 text-center">
                        <Link to="/register" className="text-decoration-none ps-lg-5 pe-lg-5">
                            <div className="custom-button btn-custom-register p-3">Register NOW !</div>
                        </Link>
                    </div>
                </div>
            </div>

            <Gallery />

            <Footer />
        </>
    )
}