import Background from "../components/Background";
import Logo from "../assets/images/logo.png";
import FloatingAuth from "../components/FloatingAuth";
import { Link } from "react-router-dom";
import FloatingAuthenticated from "../components/FloatingAuthenticated";

export default function Index() {

  const isLoggedIn = () => {
    return localStorage.getItem('access_token') !== null;
  }

  return (
    <div className="lobby position-relative h-100">
      <Background />

      {isLoggedIn() ?
        <>
          <div className="floating-buttons d-none d-lg-block">
            <FloatingAuthenticated />
          </div>
          <div className="floating-buttons-bottom d-lg-none">
            <FloatingAuthenticated />
          </div>
        </>
        :
        <>
          <div className="floating-buttons d-none d-lg-block">
            <FloatingAuth />
          </div>
          <div className="floating-buttons-bottom d-lg-none">
            <FloatingAuth />
          </div>
        </>
      }

      <div className="lobby-content h-100 d-flex justify-content-center align-items-center flex-column pb-5 pt-5">
        <img className="img-fluid pe-5 ps-5" src={Logo} alt="KKU ICEM 2024" />
        <Link to="/home" className="text-decoration-none"><div className="lobby-text-menu">Home</div></Link>
        <Link to="/academic" className="text-decoration-none"><div className="lobby-text-menu">Academic</div></Link>
        <Link to="/non-academic" className="text-decoration-none"><div className="lobby-text-menu">Non-Academic</div></Link>
        <Link to="/guidebook" className="text-decoration-none"><div className="lobby-text-menu">Guidebook</div></Link>
        {/* <Link to="/contact-us" className="text-decoration-none"><div className="lobby-text-menu">Contact Us</div></Link> */}
      </div>
    </div>
  );
}
