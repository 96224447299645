import {
  createBrowserRouter,
  createRoutesFromElements,
  Route
} from "react-router-dom";
import Home from "../pages/Home";
import NotFound from "../pages/NotFound";
import Guidebook from "../pages/Guidebook";
import Register from "../pages/Register";
import Login from "../pages/Login";
import ForgotPassword from "../pages/ForgotPassword";
import ResetPassword from "../pages/ResetPassword";
import Academic from "../pages/Academic";
import NonAcademic from "../pages/Non-academic";
import Lobby from "../pages/Authenicated/Lobby";
import ProvideAuth from "../providers/ProvideAuth";
import Index from "../pages/Index";
import Profile from "../pages/Authenicated/Profile";
import Verification from "../pages/Authenicated/Verification";
import TeamInformation from "../pages/Authenicated/Team/TeamInformation";
import TeamCreate from "../pages/Authenicated/Team/TeamCreate";
import TripsAndSouvenirs from "../pages/Authenicated/TripsAndSouvenirs";
import TeamEdit from "../pages/Authenicated/Team/TeamEdit";
import Accommodations from "../pages/Authenicated/Accommodations";
import Payment from "../pages/Authenicated/Payment";


const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/" element={<Index />} />
      <Route path="/home" element={<Home />} />
      <Route path="/register" element={<Register />} />
      <Route path="/login" element={<Login />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/reset-password/:token" element={<ResetPassword />} />
      <Route path="/academic" element={<Academic />} />
      <Route path="/non-academic" element={<NonAcademic />} />
      <Route path="/guidebook" element={<Guidebook />} />
      <Route path="/contact-us" element={<Home />} />
      <Route path="/lobby" element={<ProvideAuth><Lobby /></ProvideAuth>} />

      <Route path="/profile" element={<ProvideAuth><Profile /></ProvideAuth>} />
      <Route path="/verification" element={<ProvideAuth><Verification /></ProvideAuth>} />
      <Route path="/team/manage/:teamId" element={<ProvideAuth><TeamInformation /></ProvideAuth>} />
      <Route path="/team/create" element={<ProvideAuth><TeamCreate /></ProvideAuth>} />
      <Route path="/team/edit/:teamId" element={<ProvideAuth><TeamEdit /></ProvideAuth>} />
      <Route path="/trips-and-souvenirs" element={<ProvideAuth><TripsAndSouvenirs /></ProvideAuth>} />
      <Route path="/accommodations/:teamId" element={<ProvideAuth><Accommodations /></ProvideAuth>} />
      <Route path="/payment/:teamId" element={<ProvideAuth><Payment /></ProvideAuth>} />
      <Route path="*" element={<NotFound />} />
    </>
  )
);

export default router;