import { useEffect, useState } from "react";
import BackgroundV2 from "../../components/BackgroundV2";
import NavbarLogo from "../../components/NavbarLogo";
import { Helmet } from "react-helmet";

import trip1 from '../../assets/images/trips/1.png';
import trip2 from '../../assets/images/trips/2.png';
import trip3 from '../../assets/images/trips/3.png';

import sizeImg from '../../assets/images/size.svg';
import shirtPreview from '../../assets/images/w.png';

import { chooseTripSchema } from "../../schema/choose-trip.schema";

import axios from "axios";
import Toastify from "toastify-js";
import { Link } from "react-router-dom";

export default function TripsAndSouvenirs() {

    const SIZES = ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL', '6XL'];
    const [trip, setTrip] = useState('');
    const [option, setOption] = useState('');
    const [size, setSize] = useState('');

    useEffect(() => {
        getTrip();
    }, [])

    const getTrip = () => {
        axios.get('/api/trips').then((res) => {
            console.log(res.data.data);
            setTrip(res.data.data.trip_name);
            setOption(res.data.data.options);
            setSize(res.data.data.size);
        }).catch((err) => {
            console.log(err);
        })
    }

    const save = (e) => {

        // disable button
        e.target.disabled = true;
        const originalText = e.target.innerHTML;
        e.target.innerHTML = 'Saving...';

        const validate = chooseTripSchema.validate({
            trip: trip,
            option: option,
            size: size
        });

        if (validate?.error) {
            Toastify({
                text: validate.error.message,
                duration: 3000,
                gravity: "bottom",
                position: "center",
                backgroundColor: "#dc3545",
                stopOnFocus: true,
            }).showToast();
            e.target.disabled = false;
            e.target.innerHTML = originalText;
            return;
        }

        console.log('save', trip, option);

        axios.post('/api/trips', {
            trip: trip,
            options: option,
            size: size
        }).then((res) => {
            Toastify({
                text: 'Trip saved.',
                duration: 3000,
                gravity: "bottom",
                position: "center",
                backgroundColor: "#064B72",
                stopOnFocus: true,
                onClick: function () { },
                callback: function () { },
            }).showToast();
        }).catch((err) => {
            Toastify({
                text: err.response.data.message,
                duration: 3000,
                gravity: "bottom",
                position: "center",
                backgroundColor: "#dc3545",
                stopOnFocus: true,
            }).showToast();
        }).finally(() => {
            e.target.disabled = false;
            e.target.innerHTML = originalText;
        })
    }

    // const choose = (e, id, value) => {

    //     // remove all active
    //     const elements = document.querySelectorAll('.card');
    //     elements.forEach(element => {
    //         element.classList.remove('active-set');
    //         element.classList.remove('is-invalid');
    //     });

    //     // add active
    //     document.getElementById(id).classList.add('active-set');

    //     // set trip
    //     setTrip(value);

    //     if (value === 'Thai aesthetics') {
    //         document.getElementById('options').style.display = 'block';
    //         setOption('');
    //     } else {
    //         document.getElementById('options').style.display = 'none';
    //         setOption(null);
    //     }

    // }

    return (
        <>
            <BackgroundV2 props={{ height: '75vh' }} />
            <NavbarLogo />

            <Helmet>
                <style>{`
                    html, body {
                        padding-top: 75px;
                    }
                `}</style>
            </Helmet>

            <div className="container pb-5">
                <div className="row justify-content-center mb-4 mt-4">
                    <div className="col-12 col-lg-8 mb-4">
                        <h3 className="mb-3 text-center"><span className="label-blue">choose</span> <span className="label-red">your Trip</span></h3>
                    </div>
                </div>

                <div className="row justify-content-center mb-2 mt-4">
                    <div className="col-12 col-lg-8 mb-4">
                        <div className="card rounded">
                            <div className="card-body">
                                <div className="fw-bold">Choose your trip</div>

                                <div className="row mt-3">
                                    <div className="col-12 col-md-4 mb-3">
                                        <div class="card h-100 position-relative clickable" onClick={(e) => { setTrip('Thai aesthetics'); }}>
                                            <img src={trip1} class="card-img-top img-fluid" alt="Trip 1" />
                                            <div class="card-body">
                                                <div className="row">
                                                    <div className="col-2 d-flex justify-content-center align-items-center">
                                                        <div class="form-check">
                                                            <input class="form-check-input" type="radio" name="trip" id="trip1" onChange={(e) => { setTrip(e.target.value) }} value="Thai aesthetics" checked={trip === 'Thai aesthetics'} />
                                                            <label class="form-check-label" for="trip1">
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col-10">
                                                        <div class="card-title fw-bold">Thai aesthetics</div>
                                                        <div className="small-font">Enjoy exclusive view of Khonkaen Downtown from a  9 storied Nongwaeng  Pagoda and a relaxing authentic Thai massage</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-12 col-md-4 mb-3">
                                        <div class="card h-100 position-relative clickable" id="trip-2" onClick={(e) => { setTrip('Columbo craft village'); }}>
                                            <img src={trip2} class="card-img-top img-fluid" alt="Trip 2" />
                                            <div class="card-body">
                                                <div className="row">
                                                    <div className="col-2 d-flex justify-content-center align-items-center">
                                                        <div class="form-check">
                                                            <input class="form-check-input" type="radio" name="trip" id="trip2" onChange={(e) => { setTrip(e.target.value) }} value="Columbo craft village" checked={trip === 'Columbo craft village'} />
                                                            <label class="form-check-label" for="trip2">
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col-10">
                                                        <div class="card-title fw-bold">Columbo craft village</div>
                                                        <div className="small-font">Enhance your imagination and craftsmanship with various DIY stuffs at Columbo craft village</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-12 col-md-4 mb-3">
                                        <div class="card h-100 position-relative clickable" id="trip-3" onClick={(e) => { setTrip('Mekin farm'); }}>
                                            <img src={trip3} class="card-img-top img-fluid" alt="Trip 3" />
                                            <div class="card-body">
                                                <div className="row">
                                                    <div className="col-2 d-flex justify-content-center align-items-center">
                                                        <div class="form-check">
                                                            <input class="form-check-input" type="radio" name="trip" id="trip3" onChange={(e) => { setTrip(e.target.value) }} value="Mekin farm" checked={trip === 'Mekin farm'} />
                                                            <label class="form-check-label" for="trip3">
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col-10">
                                                        <div class="card-title fw-bold">Mekin farm</div>
                                                        <div className="small-font">Experience Thai-rural lifestyle and explore organic food fresh from farm at Mekin farm</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div id="options" style={{ display: trip === 'Thai aesthetics' ? 'block' : 'none' }}>
                                    <div className="fw-bold mt-4 mb-2">Thai aesthetics options</div>

                                    <div className="row">
                                        <div className="col-12 col-md-6 mb-3">
                                            <div className="option-box clickable" onClick={(e) => { setOption('With Thai massage'); }}>
                                                <div class="form-check">
                                                    <input class="form-check-input" type="radio" name="options" id="option1" onChange={(e) => { setOption(e.target.value) }} value="With Thai massage" checked={option === 'With Thai massage'} />
                                                    <label class="form-check-label" for="option1">
                                                        With Thai massage
                                                    </label>
                                                    <div className="invalid-feedback" id="option1Feedback"></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6 mb-3">
                                            <div className="option-box clickable" onClick={(e) => { setOption('Without Thai massage'); }}>
                                                <div class="form-check">
                                                    <input class="form-check-input" type="radio" name="options" id="option2" onChange={(e) => { setOption(e.target.value) }} value="Without Thai massage" checked={option === 'Without Thai massage'} />
                                                    <label class="form-check-label" for="option2">
                                                        Without Thai massage
                                                    </label>
                                                    <div className="invalid-feedback" id="option2Feedback"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <hr />

                                <div className="row justify-content-center mb-2 mt-4">
                                    <div className="col-12 mb-4">
                                        <div className="fw-bold">Souvenir</div>
                                    </div>
                                    <div className="col-12 col-md-8 text-center mb-3 align-self-center">
                                        <img src={shirtPreview} class="card-img-top img-fluid" alt="Shirt preview" />
                                    </div>
                                    <div className="col-12 col-md-4 text-center mb-3 align-self-center">
                                        <img src={sizeImg} class="card-img-top img-fluid" alt="Size" style={{ maxWidth: 180 }} />
                                    </div>
                                </div>

                                <div className="row justify-content-center mb-4">
                                    {SIZES.map((item, index) => (
                                        <div className="col-12 col-md-6 col-lg-4 mb-3" key={index}>
                                            <div className="option-box clickable" onClick={(e) => { setSize(item); }}>
                                                <div class="form-check">
                                                    <input class="form-check-input" type="radio" name="size" id={item} onChange={(e) => { setSize(e.target.value) }} value={item} checked={size === item} />
                                                    <label class="form-check-label" for={item}>
                                                        {item}
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row mb-4 mt-2">
                        <div className="col-12 text-center">
                            <button className="btn btn-custom-register" onClick={save}>
                                Choose
                            </button>
                        </div>
                        <div className="col-12 text-center mt-3">
                            <Link to="/lobby" className="text-white">Go to lobby</Link>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}
