import timeline1 from '../assets/images/timeline/timeline1.png';
import timeline2 from '../assets/images/timeline/timeline2.png';
import timeline3 from '../assets/images/timeline/timeline3.png';
import timeline4 from '../assets/images/timeline/timeline4.png';

export default function Timeline() {
    return (
        <div className="container-fluid mt-5 mb-5">
            <div className="row">
                <div className="col-12 pe-lg-5 ps-lg-5">
                    <div className="timeline">
                        <div className="text-center">
                            <h1 className="label-red text-divider">Timeline</h1>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row mb-5 mt-5 pe-lg-5 ps-lg-5">
                <div className="col-12 col-md-6 col-lg-3 text-center mb-4">
                    <div>
                        <img src={timeline1} alt="Early Bird Registration" className="img-fluid" />
                    </div>
                    <div className="card h-100 transparent" style={{ maxHeight: '100px' }}>
                        <div className="card-body d-flex flex-column justify-content-center">
                            <h4 className="label-blue">EARLY BIRD </h4>
                            <h4 className="label-red">REGISTRATION</h4>
                        </div>
                    </div>
                    <div className="text-white">21st November, 2023 (12:00 AM GMT +7:00)</div>
                    <div className="text-white">to</div>
                    <div className="text-white detail-highlight">5th December, 2023 (11:59 PM GMT +7:00)</div>
                </div>
                <div className="col-12 col-md-6 col-lg-3 text-center mb-4">
                    <div>
                        <img src={timeline2} alt="Registration" className="img-fluid" />
                    </div>
                    <div className="card h-100 transparent" style={{ maxHeight: '100px' }}>
                        <div className="card-body d-flex flex-column justify-content-center">
                            <h4 className="label-red">REGISTRATION</h4>
                        </div>
                    </div>
                    <div className="text-white">6th December, 2023 (12:00 AM GMT +7:00)</div>
                    <div className="text-white">to</div>
                    <div className="text-white detail-highlight"> 31st December, 2023 (11:59 PM GMT+7:00)</div>
                </div>
                <div className="col-12 col-md-6 col-lg-3 text-center mb-4">
                    <div>
                        <img src={timeline3} alt="Team Announcement" className="img-fluid" />
                    </div>
                    <div className="card h-100 transparent" style={{ maxHeight: '100px' }}>
                        <div className="card-body d-flex flex-column justify-content-center">
                            <h4 className="label-blue">TEAM</h4>
                            <h4 className="label-red">ANNOUNCEMENT</h4>
                        </div>
                    </div>
                    <div className="text-white detail-highlight">2nd February, 2024 (GMT +7:00)</div>
                </div>
                <div className="col-12 col-md-6 col-lg-3 text-center mb-4">
                    <div>
                        <img src={timeline4} alt="Competition Date" className="img-fluid" />
                    </div>
                    <div className="card h-100 transparent" style={{ maxHeight: '100px' }}>
                        <div className="card-body d-flex flex-column justify-content-center">
                            <h4 className="label-blue">COMPETITION</h4>
                            <h4 className="label-red">DATE</h4>
                        </div>
                    </div>
                    <div className="text-white detail-highlight">23rd - 27th February, 2024 (GMT +7:00)</div>
                </div>
            </div>
        </div>
    )
}