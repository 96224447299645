import { Link } from 'react-router-dom';
import logo from '../assets/images/logo.png';
import { useEffect } from 'react';

export default function NavbarLogo() {

    const logout = () => {
        localStorage.removeItem('access_token');
        window.location.href = '/';
    }

    useEffect(() => {
        const home = document.getElementById('home-navbar')
        const logout = document.getElementById('logout-navbar')

        if (window.location.pathname === '/register' || window.location.pathname === '/login') {
            home.style.display = 'none'
            logout.style.display = 'none'
        }
    }, [])

    return (
        <nav className="navbar navbar-expand-lg fixed-top navbar-bottom">
            <div className="container d-block">
                <div className='row'>
                    <div className='col-4 text-start align-self-center'>
                        <Link to="/" className="text-decoration-none" id="home-navbar">
                            <div className='label-blue'><i class="fa-solid fa-house"></i> Home</div>
                        </Link>
                    </div>
                    <div className='col-4 text-center'>
                        <Link to="/">
                            <img src={logo} alt="logo" className="d-inline-block align-text-top" width="120" />
                        </Link>
                    </div>
                    <div className='col-4 text-end align-self-center'>
                        <Link to="#" onClick={logout} className="text-decoration-none" id="logout-navbar">
                            <div className='label-red'><i class="fa-solid fa-power-off"></i> Logout</div>
                        </Link>
                    </div>
                </div>
            </div>
        </nav>
    )
} 