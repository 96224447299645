import { Link } from "react-router-dom";
import Background from "../components/Background";

export default function NotFound() {
    return (
        <>
            <Background />

            <div className="lobby-content h-100 d-flex justify-content-center align-items-center flex-column pb-5 pt-5">
                <h1 className="text-center text-white mb-2">404 Not Found</h1>
                <h5 className="text-center text-white mb-4">The page you are looking for does not exist.</h5>
                <Link to="/" className="text-decoration-none"><div className="lobby-text-menu">Home</div></Link>
            </div>
        </>
    );
}
