import axios from "axios";
import { useEffect, useState } from "react";
import Background from "../components/Background";
import Preload from "../components/Preload";

export default function ProvideAuth({ children }) {

    const token = localStorage.getItem("access_token");
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        axios.post('/api/auth/verify-token', { token }).then((res) => {
            return setIsLoaded(true);
        }).catch((err) => {
            localStorage.removeItem('access_token');
            return window.location.href = '/login';
        }).finally(() => {
            console.log('Request completed.');
            setIsLoaded(true);
        })
    }, [])

    return (
        <>
            {isLoaded ?
                [children]
                :
                <>
                    <Background />
                    <Preload />
                </>

            }
        </>
    );
}