import BackgroundV2 from "../../components/BackgroundV2";
import NavbarLogo from "../../components/NavbarLogo";
import { Helmet } from "react-helmet";

import paymentGuide from '../../assets/images/payment-guide.png';
import Toastify from "toastify-js";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { useParams } from "react-router-dom";
import Preload from "../../components/Preload";

export default function Payment() {

    const [next, setNext] = useState(false);
    const [data, setData] = useState(null);

    const [image, setImage] = useState(null);
    const [requiredReceipt, setRequiredReceipt] = useState(null);
    const [transaction, setTransaction] = useState(null);
    const [loading, setLoading] = useState(true);

    const { teamId } = useParams();

    useEffect(() => {
        getTransaction();
    }, [next])

    const getTransaction = () => {
        axios.get('/api/transactions/' + teamId).then((res) => {
            setData(res.data.data);
            setTransaction(res.data.transaction);
            setRequiredReceipt(res.data.data.required_receipt === "true" ? "yes" : "no");
            if (res.data.data.transaction) {
                document.getElementById('image-name').innerHTML = res.data.data.transaction;
            }
        }).catch((err) => {
            console.log(err);
        }).finally(() => {
            setLoading(false);
        })
    }

    const copyToClipboard = (text) => {
        var dummy = document.createElement("textarea");
        document.body.appendChild(dummy);
        dummy.value = text;
        dummy.select();
        document.execCommand("copy");
        document.body.removeChild(dummy);
        Toastify({
            text: "Copied to clipboard!",
            duration: 3000,
            gravity: "bottom",
            position: 'center',
            backgroundColor: '#2D2D2D',
            className: 'info',
            stopOnFocus: true,
        }).showToast();
    }

    const nextPage = () => {
        setNext(true);
    }

    const renderPaymentGuide = () => {
        return (
            <div className="container pb-5">
                <div className="row justify-content-center mb-3">
                    <div className="col-12 col-lg-8 mb-4">
                        <h3 className="mb-3 text-center"><span className="label-blue">How to make a transaction?</span></h3>
                    </div>
                </div>
                <div className="row justify-content-center mb-4 mt-4">
                    <div className="col-12 col-lg-6">
                        <img src={paymentGuide} alt="paymentGuide" className="img-fluid" />
                    </div>
                    <div className="col-12 text-center">
                        <button className="btn custom-button btn-custom-register mb-3" onClick={() => copyToClipboard(`ACCOUNT BENEFICIARY: KKU-ICEM\nACCOUNT NUMBER: 551-442424-1\nBANK NAME: SIAM COMMERCIAL BANK PLC\nBRANCH: KHON KAEN UNIVERSITY\nADDRESS: 123/900  MITTRAPHAP ROAD, NAIMUANG  MUANG KHONKAEN  40002\nSWIFT CODE: SICOTHBK`)}>Copy Information</button>
                    </div>
                </div>

                <div className="row justify-content-center mb-2">
                    <div className="col-12 col-lg-6 text-white">
                        <div>Please transfer the registration fee to the account above</div>
                        <div>Team (consisting of 3 members)</div>
                        <ul>
                            <li>Early bird registration 15,000 THB/team</li>
                            <li>Regular registration 18,000 THB/team</li>
                        </ul>
                        <div>Advisor (required) and observer</div>
                        <ul>
                            <li>Early bird registration 4,000 THB/person</li>
                            <li>Regular registration 5,000 THB/person</li>
                        </ul>

                        <div>The registration fees listed above DO NOT include any accommodation</div>
                        <div className="mb-3">You can choose your own preferred hotel rooms and pay accordingly via our website</div>

                        <div>Please upload the transaction document in the next section.</div>
                        <div className="mb-3">Confirmation email will be sent after the document has been checked.</div>

                        <div>For more information and questions, feel free to contact us via</div>
                        <div>kkuicem.official@gmail.com</div>
                    </div>
                </div>


                <div className="row justify-content-center mb-4">
                    <div className="col-12 col-lg-6 text-white text-center">
                        <button className="btn custom-button btn-custom-register mb-3" onClick={() => nextPage()}>
                            <h4 className="mt-2">Upload Transaction document</h4>
                        </button>
                    </div>

                    <div className="col-12 text-center mt-3">
                        <Link to={`/team/manage/${teamId}`} className="text-white">Go to team</Link>
                    </div>

                </div>
            </div>
        )
    }

    const handleImage = (e) => {
        const { files } = e.target;
        setImage(files[0]);
        document.getElementById('image-name').innerHTML = files[0].name;
    }

    const save = () => {
        if (!requiredReceipt) {
            document.getElementById('receipt').classList.add('is-invalid');
            document.getElementById('receiptFeedback').innerHTML = 'Please choose one';
            return;
        }

        const originalText = document.getElementById('upload').innerHTML;
        document.getElementById('upload').innerHTML = '<i class="fa-solid fa-circle-notch fa-spin"></i>';
        document.getElementById('upload').disabled = true;


        const formData = new FormData();
        formData.append('image', image);
        formData.append('required_receipt', requiredReceipt === 'yes' ? true : false);
        formData.append('team_id', teamId);

        axios.post('/api/transactions/save', formData, {
            headers: {
                common: {
                    'Content-Type': 'multipart/form-data',
                }
            }
        }).then((res) => {
            Toastify({
                text: 'Transaction document uploaded.',
                duration: 3000,
                gravity: "bottom",
                position: "center",
                backgroundColor: "#064B72",
                stopOnFocus: true,
                onClick: function () { },
                callback: function () { },
            }).showToast();
        }).catch((err) => {
            console.log(err);
        }).finally(() => {
            document.getElementById('upload').innerHTML = originalText;
            document.getElementById('upload').disabled = false;
        })
    }

    return (
        <>
            <BackgroundV2 props={{ height: '75vh' }} />
            <NavbarLogo />

            <Helmet>
                <style>{`
                    html, body {
                        padding-top: 75px;
                    }
                `}</style>
            </Helmet>

            {loading ?
                <Preload />
                :
                <>
                    {!next ?
                        renderPaymentGuide()
                        :
                        <div className="container pb-5">
                            <div className="row justify-content-center mb-3">
                                <div className="col-12 col-lg-6 mb-4">
                                    <h5 className="text-white text-center">Transaction document</h5>
                                    <di className="text-center text-white">ㅤ</di>
                                    <div className="card mt-4">
                                        <label className="card-body text-center d-flex justify-content-center align-items-center flex-column" style={{ height: 300, cursor: 'pointer' }} for="proof">
                                            <i class="fa-solid fa-file-arrow-up" style={{ fontSize: 120 }}></i>
                                            <div className="text-center text-dark mt-3">Drag file to upload</div>
                                            <input className="form-control" type="file" id="image" hidden onChange={handleImage} accept="image/*" />
                                        </label>
                                        <label className="card-footer text-center p-3" style={{ color: "#064B72", cursor: 'pointer' }} for="image">
                                            Choose file to upload
                                        </label>
                                    </div>

                                    <div className="card mt-4">
                                        <div className="card-body">
                                            <Link to={data ? data.image_url : '#'} {...(data ? { target: "_blank" } : {})} rel="noreferrer">
                                                <div className="d-flex justify-content-between">
                                                    <div><i class="fa-regular fa-file-lines"></i></div>
                                                    <div className="text-end" id="image-name">
                                                        {transaction ? transaction : <i class="fa-solid fa-circle-notch fa-spin"></i>}
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row justify-content-center mb-4">
                                <div className="col-12 col-lg-4 col-md-6 text-center text-white">
                                    <div className="form-group">
                                        <label for="receipt" className="mb-2">Require payment receipt? <span className="text-danger">*</span></label>
                                        <select className="form-control" id="receipt" onChange={(e) => setRequiredReceipt(e.target.value)} defaultValue={data ? data.required_receipt === "true" ? "yes" : "no" : "0"} >
                                            <option value="0" disabled>Please choose one</option>
                                            <option value="yes">Yes</option>
                                            <option value="no">No</option>
                                        </select>
                                        <div className="invalid-feedback" id="receiptFeedback"></div>
                                    </div>
                                </div>
                            </div>

                            <div className="row justify-content-center">
                                <div className="col-12 col-lg-6 text-white text-center">
                                    <button className="btn custom-button btn-custom-register mb-3" id="upload" onClick={() => save()}>
                                        <h4 className="mt-2">Submit</h4>
                                    </button>
                                </div>

                                <div className="col-12 text-center mt-3">
                                    <Link to={`/team/manage/${teamId}`} className="text-white">Go to team</Link>
                                </div>
                            </div>

                        </div>
                    }
                </>
            }
        </>
    )
}