import { useEffect, useState } from "react";
import BackgroundV2 from "../../../components/BackgroundV2";
import NavbarLogo from "../../../components/NavbarLogo";
import { Helmet } from "react-helmet";
import axios from "axios";
import Toastify from 'toastify-js'
import { createTeamSchema } from "../../../schema/create-team.schema";
import { Link, useParams } from "react-router-dom";
import Preload from "../../../components/Preload";

export default function TeamEdit() {

    const { teamId } = useParams();

    const [isLoaded, setIsLoaded] = useState(false);
    const [isCreating, setIsCreating] = useState(false);
    const [image, setImage] = useState('');
    const [universityName, setUniversityName] = useState('');
    const [universityAddress, setUniversityAddress] = useState('');
    const [teamName, setTeamName] = useState('');
    const [numberOfObservers, setNumberOfObservers] = useState('');

    useEffect(() => {
        loadTeam();
    }, [])

    const loadTeam = () => {
        axios.get('/api/teams/' + teamId).then((res) => {
            setIsLoaded(true);
            const data = res.data.data;
            setUniversityName(data.university_name);
            setUniversityAddress(data.university_address);
            setTeamName(data.team_name);
            // setNumberOfObservers(data.number_of_observers);
            const preview = document?.getElementById('image-preview')
            if (preview) {
                preview.src = data.logo;
            }
        }).catch((err) => {
            return Toastify({
                text: 'You have no permission to access this page.',
                duration: 3000,
                gravity: 'bottom',
                position: 'center',
                backgroundColor: 'linear-gradient(to right, #ff5f6d, #ffc371)',
                className: 'error',
                onClick: function () {
                    window.location.href = '/lobby';
                },
                callback: function () {
                    window.location.href = '/lobby';
                }
            }).showToast();
        }).finally(() => {
            console.log('Request completed.');
        })
    }

    const handleImageChange = (e) => {
        setImage(e.target.files[0]);
        const { files } = e.target;
        if (files && files[0]) {
            let reader = new FileReader();
            reader.onload = function (e) {
                document.getElementById('image-preview').src = e.target.result;
            }
            reader.readAsDataURL(files[0]);
        }
    }

    const update = () => {
        if (isCreating) {
            return Toastify({
                text: 'Please wait for a moment.',
                duration: 3000,
                gravity: 'bottom',
                position: 'center',
                backgroundColor: 'linear-gradient(to right, #00b09b, #96c93d)',
                className: 'info',
            }).showToast();
        };
        setIsCreating(true);
        const payload = {
            image: image,
            university_name: universityName,
            university_address: universityAddress,
            team_name: teamName,
            // number_of_observers: numberOfObservers.toString(),
        }

        const validate = createTeamSchema.validate(payload, { abortEarly: false });
        if (validate?.error) {
            validate.error.details.forEach((err) => {
                document.getElementById(err.context.key).classList.add('is-invalid');
                document.getElementById(err.context.key + 'Feedback').innerHTML = err.message;
                console.log(err)
            })
            setIsCreating(false);
            return;
        }

        const originalText = document.getElementById('update').innerHTML;
        document.getElementById('update').innerHTML = '<i class="fa-solid fa-circle-notch fa-spin text-white"></i>';

        axios.post('/api/teams/update', payload, {
            headers: {
                common: {
                    'Content-Type': 'multipart/form-data'
                }
            }
        }).then((res) => {
            if (res.status === 200) {
                return Toastify({
                    text: 'Team profile has been updated.',
                    duration: 3000,
                    gravity: 'bottom',
                    position: 'center',
                    backgroundColor: 'linear-gradient(to right, #00b09b, #96c93d)',
                    className: 'info',
                }).showToast();
            } else {
                return Toastify({
                    text: res.data.message,
                    duration: 3000,
                    gravity: 'bottom',
                    position: 'center',
                    backgroundColor: 'linear-gradient(to right, #ff5f6d, #ffc371)',
                    className: 'error',
                }).showToast();
            }
        }).catch((err) => {
            return Toastify({
                text: 'An error has occurred. Please try again later.',
                duration: 3000,
                gravity: 'bottom',
                position: 'center',
                backgroundColor: 'linear-gradient(to right, #ff5f6d, #ffc371)',
                className: 'error',
            }).showToast();
        }).finally(() => {
            console.log('Request completed.');
            setIsCreating(false);
            document.getElementById('update').innerHTML = originalText;
        });
    }

    const onInputChange = (e) => {
        const { id, value } = e.target;
        document.getElementById(id).classList.remove('is-invalid');
        document.getElementById(id + 'Feedback').innerHTML = '';
        switch (id) {
            case 'university_name':
                setUniversityName(value);
                break;
            case 'university_address':
                setUniversityAddress(value);
                break;
            case 'team_name':
                setTeamName(value);
                break;
            // case 'number_of_observers':
            //     setNumberOfObservers(value);
            //     break;
            default:
                break;
        }
    }

    return (
        <>
            <BackgroundV2 props={{ height: '75vh' }} />
            <NavbarLogo />
            <Helmet>
                <style>{`
                    html, body {
                        padding-top: 75px;
                    }
                `}</style>
            </Helmet>

            {isLoaded ?
                <div className="container pb-5">
                    <div className="row">
                        <div className="col-12 text-center">
                            <h3 className="mb-3"><span className="label-blue">Team</span><span className="label-red">Profile !</span></h3>
                            <div className="form-group mb-3">
                                <input type="file" className="d-none" id="image" hidden onChange={handleImageChange} accept="image/*" />
                                <div className="d-flex justify-content-center">
                                    <div className="position-relative">
                                        <img src="https://pub-3d6ffa377ce448019676a38678d8594b.r2.dev/user.png" alt="team" className="rounded-circle" width="150" height={150} id="image-preview" style={{ border: '5px solid #fff' }} />
                                        <div className="position-absolute bottom-0 end-0">
                                            <label htmlFor="image">
                                                <i className="fas fa-plus-circle fa-2x text-danger"></i>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="invalid-feedback" id="imageFeedback"></div>
                            </div>

                            <div className="row justify-content-center mt-5">
                                <div className="col-12 col-md-6 col-lg-4">
                                    <div className="form-group mb-3">
                                        <input type="text" className="form-control" placeholder="University name" id="university_name" onChange={onInputChange} value={universityName} />
                                        <div className="invalid-feedback" id="university_nameFeedback"></div>
                                    </div>
                                </div>
                            </div>
                            <div className="row justify-content-center mt-2">
                                <div className="col-12 col-md-6 col-lg-4">
                                    <div className="form-group mb-3">
                                        <textarea className="form-control" placeholder="University address" rows="5" id="university_address" onChange={onInputChange} value={universityAddress} />
                                        <div className="invalid-feedback" id="university_addressFeedback"></div>
                                    </div>
                                </div>
                            </div>
                            <div className="row justify-content-center mt-2">
                                <div className="col-12 col-md-6 col-lg-4">
                                    <div className="form-group mb-3">
                                        <input type="text" className="form-control" placeholder="Team name (university initials)" id="team_name" onChange={onInputChange} value={teamName} />
                                        <div className="invalid-feedback" id="team_nameFeedback"></div>
                                    </div>
                                </div>
                            </div>

                            <div className="row justify-content-center mt-2 text-white">
                                <div className="col-12 col-md-6 col-lg-4 text-center">
                                    For multiple team registration of the same university, each team’s name should be the university initials followed by a number e.g. KKU1, KKU2
                                </div>
                            </div>

                            {/* <div className="row justify-content-center mt-2">
                                <div className="col-12 col-md-6 col-lg-4">
                                    <div className="form-group mb-3">
                                        <select className="form-control" id="number_of_observers" onChange={onInputChange}>
                                            <option value="" disabled selected>Number of observer(s)</option>
                                            <option value="0" selected={numberOfObservers === 0}>0</option>
                                            <option value="1" selected={numberOfObservers === 1}>1</option>
                                            <option value="2" selected={numberOfObservers === 2}>2</option>
                                            <option value="3" selected={numberOfObservers === 3}>3</option>
                                            <option value="4" selected={numberOfObservers === 4}>4</option>
                                            <option value="5" selected={numberOfObservers === 5}>5</option>
                                        </select>
                                        <div className="invalid-feedback" id="number_of_observersFeedback"></div>
                                    </div>
                                </div>
                            </div> */}

                            <div className="row justify-content-center mt-2 text-white">
                                <div className="col-12 col-md-6 col-lg-6 text-center">
                                    <div>Our registration team will contact you back shortly via email. </div>
                                    <div>For more information and questions, feel free to contact us via</div>
                                    <div>kkuicem.official@gmail.com</div>
                                </div>
                            </div>

                            <div className="row justify-content-center mt-4">
                                <div className="col-12 col-md-6 col-lg-4">
                                    <div className="d-flex justify-content-center">
                                        <div className="custom-button btn-custom-register" id="update" onClick={update}>Update</div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="row mb-4 mt-2">
                        <div className="col-12 text-center mt-3">
                            <Link to={`/team/manage/${teamId}`} className="text-white">Go back</Link>
                        </div>
                    </div>
                </div>
                :
                <Preload />
            }
        </>
    )
}